import { cloneDeep } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { PrintingQueue, PrintingResult } from "../../types";
import { getStorageObject, setStorageObject } from "../../utils/StorageWrapper";
import { useRT } from "./useRT";

/**
 * Handles the Printing Queue for a specific cash System IP.
 *
 * It leverages the local storage to store unprinted printing items.
 * It handles also common Cash System error states (e.g. out of paper).
 * By calling the "process" function you can try to print afterward
 * the printing items not printed during the usual app flow.
 *
 * @returns
 */
const useRtPrinter = () => {
	const RT = useRT();

	const defaultQueue = useMemo(() => {
		return { queue: [] };
	}, []);

	const [isCashSystemInError, setIsCashSystemInError] = useState<boolean>(false);
	const [isCashSystemOutOfPaper, setCashSystemIsOutOfPaper] = useState<boolean>(false);
	const [isProcessed, setIsProcessed] = useState<boolean>(false);
	const [printingQueue, setPrintingQueue] = useState<PrintingQueue>(defaultQueue);

	/**
	 * Retrieve the printing queue from the local storage
	 */
	const retrieveFromStorage = useCallback((): PrintingQueue => {
		return (getStorageObject("printingQueue") as PrintingQueue) ?? defaultQueue;
	}, [defaultQueue]);

	/**
	 * Put the printing queue into the local storage
	 */
	const putIntoStorage = useCallback((printingQueue: PrintingQueue): void => {
		return setStorageObject("printingQueue", printingQueue);
	}, []);

	/**
	 * This function empties the printing queue and reset all the state flags
	 */
	const reset = useCallback((): void => {
		setIsCashSystemInError(false);
		setCashSystemIsOutOfPaper(false);
		setPrintingQueue(defaultQueue);
		putIntoStorage(defaultQueue);
		setIsProcessed(true);
	}, [defaultQueue, putIntoStorage]);

	/**
	 * it process the queue currently stored in local storage
	 */
	const process = useCallback((): void => {
		// reset flags
		setIsCashSystemInError(false);
		setCashSystemIsOutOfPaper(false);
		setIsProcessed(false);

		// if non empty queue, process it
		const localQueue = retrieveFromStorage();
		if (localQueue.queue.length === 0) {
			setIsProcessed(true);
			return;
		}
		setPrintingQueue(localQueue);
	}, [retrieveFromStorage]);

	useEffect(() => {
		/**
		 * print out first item in queue. In case of success remove the item from the queue and updated state and localStorage
		 */
		const printFirstItem = async () => {
			const localQueue = cloneDeep(printingQueue);
			await RT.printingHandler(localQueue.queue[0]).then((printingResult: PrintingResult) => {
				if (printingResult.isInError) {
					setIsCashSystemInError(true);
				} else {
					if (printingResult.isPrinted) {
						localQueue.queue.shift();
						setPrintingQueue({ ...printingQueue, queue: localQueue.queue });
						putIntoStorage({ ...printingQueue, queue: localQueue.queue });
						if (localQueue.queue.length === 0) setIsProcessed(true);
					} else {
						setCashSystemIsOutOfPaper(true);
					}
				}
			});
		};

		if (printingQueue.queue.length === 0 || isCashSystemInError || isCashSystemOutOfPaper) return;

		printFirstItem();
	}, [printingQueue, isCashSystemInError, isCashSystemOutOfPaper, putIntoStorage, RT]);

	return {
		RT,
		isCashSystemOutOfPaper,
		isCashSystemInError,
		isPrintingQueueProcessed: isProcessed,
		resetPrintingQueue: reset,
		processPrintingQueue: process,
		retrievePrintingQueue: retrieveFromStorage,
		storePrintingQueue: putIntoStorage
	};
};

export default useRtPrinter;
