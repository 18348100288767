import { Box, Container, Paper, ThemeProvider } from "@mui/material";
import { memo, ReactNode } from "react";
import { useGetRestaurantMedia } from "../../../../hooks/useCdnAssets";
import { useTheme } from "../../../Theme/ThemeWrapper";
import "./ConfirmModal.scss";

type Props = {
	children: ReactNode[];
	outsideNode?: ReactNode;
};

const ConfirmModal = ({ children, outsideNode }: Props) => {
	const { theme } = useTheme();
	const { url: bgUrl, isFallback: isBgFallback } = useGetRestaurantMedia("plain");
	const { url: logoUrl, isFallback: isLogoFallback } = useGetRestaurantMedia("logo");

	const lightColor: string = theme.customTheme.palette.primary.light;
	const darkColor: string = theme.customTheme.palette.primary.dark;

	return (
		<ThemeProvider theme={theme.systemTheme}>
			<Container
				className="confirm-modal-container"
				sx={{ background: `${isBgFallback ? "" : "url(" + bgUrl + "), "}conic-gradient(from 90deg, ${lightColor}, ${darkColor}, ${lightColor})` }}
			>
				{outsideNode ? <Box className="confirm-modal-outside">{outsideNode}</Box> : null}
				<Paper className="confirm-modal-box" elevation={20}>
					<Box className="imageContainer">{isLogoFallback ? null : <img className="customLogo" src={logoUrl} alt="" loading="lazy" />}</Box>
					<Box className="children-box">{children[0]}</Box>
				</Paper>
				<Box className="confirm-modal-button-box">{children[1]}</Box>
			</Container>
		</ThemeProvider>
	);
};

export default memo(ConfirmModal);
