/**
 * Encodes given string in hexadecimal
 *
 * @param str
 * @returns
 */
export function hexEncode(str: string): string {
	let hex, i;

	let result = "";
	for (i = 0; i < str.length; i++) {
		hex = str.charCodeAt(i).toString(16);
		result += hex.slice(-4);
	}

	return result;
}

/**
 * Converts given string in title case.
 *
 * @param str
 * @returns
 */
export function toTitleCase(str: string) {
	return str.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());
}

/**
 * get the size of a string expressed in byte
 *
 * @param str
 * @returns
 */
export function byteSize(str: string) {
	return new Blob([str]).size;
}

/**
 * Trim out the substring from the first open round bracket
 *
 * @param str
 * @returns
 */
export function trimAfterRoundBracket(str: string) {
	if (!str.includes("(")) return str;
	return str.substring(0, str.indexOf("("));
}

/**
 * Converts a string using ASCII characters only.
 * Replaces each character in the string with ASCII fallback string defined in asciiSafeMap or a blank character.
 *
 * @param str
 * @returns
 */
export function asciiSafeConverter(str: string): string {
	let resultStr: string = "";

	for (let i = 0; i < str.length; i++) {
		if (str.charCodeAt(i) > 127) {
			resultStr += asciiSafeMap.find((rule: replaceRule) => rule.key === str[i])?.replace ?? " ";
		} else {
			resultStr += str[i];
		}
	}

	return resultStr;
}

interface replaceRule {
	key: string;
	replace: string;
}

const asciiSafeMap: replaceRule[] = [
	{ key: "¡", replace: "!" },
	{ key: "÷", replace: ":" },
	{ key: "À", replace: "A" },
	{ key: "Á", replace: "A" },
	{ key: "Â", replace: "A" },
	{ key: "Ã", replace: "A" },
	{ key: "Ä", replace: "A" },
	{ key: "Å", replace: "A" },
	{ key: "Ā", replace: "A" },
	{ key: "Ă", replace: "A" },
	{ key: "Ą", replace: "A" },
	{ key: "Ǎ", replace: "A" },
	{ key: "Ǟ", replace: "A" },
	{ key: "Ǡ", replace: "A" },
	{ key: "Ǻ", replace: "A" },
	{ key: "Ȁ", replace: "A" },
	{ key: "Ȃ", replace: "A" },
	{ key: "Ȧ", replace: "A" },
	{ key: "Ⱥ", replace: "A" },
	{ key: "Ɓ", replace: "B" },
	{ key: "Ƃ", replace: "B" },
	{ key: "Ƀ", replace: "B" },
	{ key: "Ç", replace: "C" },
	{ key: "Ć", replace: "C" },
	{ key: "Ĉ", replace: "C" },
	{ key: "Ċ", replace: "C" },
	{ key: "Č", replace: "C" },
	{ key: "Ƈ", replace: "C" },
	{ key: "Ȼ", replace: "C" },
	{ key: "Ď", replace: "D" },
	{ key: "Đ", replace: "D" },
	{ key: "Ɗ", replace: "D" },
	{ key: "Ƌ", replace: "D" },
	{ key: "È", replace: "E" },
	{ key: "É", replace: "E" },
	{ key: "Ê", replace: "E" },
	{ key: "Ë", replace: "E" },
	{ key: "Ē", replace: "E" },
	{ key: "Ĕ", replace: "E" },
	{ key: "Ė", replace: "E" },
	{ key: "Ę", replace: "E" },
	{ key: "Ě", replace: "E" },
	{ key: "Ȅ", replace: "E" },
	{ key: "Ȇ", replace: "E" },
	{ key: "Ȩ", replace: "E" },
	{ key: "Ɇ", replace: "E" },
	{ key: "Ƒ", replace: "F" },
	{ key: "Ĝ", replace: "G" },
	{ key: "Ğ", replace: "G" },
	{ key: "Ġ", replace: "G" },
	{ key: "Ģ", replace: "G" },
	{ key: "Ɠ", replace: "G" },
	{ key: "Ǥ", replace: "G" },
	{ key: "Ǧ", replace: "G" },
	{ key: "Ǵ", replace: "G" },
	{ key: "Ĥ", replace: "H" },
	{ key: "Ħ", replace: "H" },
	{ key: "Ȟ", replace: "H" },
	{ key: "Ì", replace: "I" },
	{ key: "Í", replace: "I" },
	{ key: "Î", replace: "I" },
	{ key: "Ï", replace: "I" },
	{ key: "Ĩ", replace: "I" },
	{ key: "Ī", replace: "I" },
	{ key: "Ĭ", replace: "I" },
	{ key: "Į", replace: "I" },
	{ key: "İ", replace: "I" },
	{ key: "Ɨ", replace: "I" },
	{ key: "Ǐ", replace: "I" },
	{ key: "Ȉ", replace: "I" },
	{ key: "Ȋ", replace: "I" },
	{ key: "Ĵ", replace: "J" },
	{ key: "Ɉ", replace: "J" },
	{ key: "Ķ", replace: "K" },
	{ key: "Ƙ", replace: "K" },
	{ key: "Ǩ", replace: "K" },
	{ key: "Ĺ", replace: "L" },
	{ key: "Ļ", replace: "L" },
	{ key: "Ľ", replace: "L" },
	{ key: "Ŀ", replace: "L" },
	{ key: "Ł", replace: "L" },
	{ key: "Ƚ", replace: "L" },
	{ key: "Ñ", replace: "N" },
	{ key: "Ń", replace: "N" },
	{ key: "Ņ", replace: "N" },
	{ key: "Ň", replace: "N" },
	{ key: "Ɲ", replace: "N" },
	{ key: "Ǹ", replace: "N" },
	{ key: "Ƞ", replace: "N" },
	{ key: "Ò", replace: "O" },
	{ key: "Ó", replace: "O" },
	{ key: "Ô", replace: "O" },
	{ key: "Õ", replace: "O" },
	{ key: "Ö", replace: "O" },
	{ key: "Ø", replace: "O" },
	{ key: "Ō", replace: "O" },
	{ key: "Ŏ", replace: "O" },
	{ key: "Ő", replace: "O" },
	{ key: "Ɵ", replace: "O" },
	{ key: "Ơ", replace: "O" },
	{ key: "Ǒ", replace: "O" },
	{ key: "Ǫ", replace: "O" },
	{ key: "Ǭ", replace: "O" },
	{ key: "Ǿ", replace: "O" },
	{ key: "Ȍ", replace: "O" },
	{ key: "Ȏ", replace: "O" },
	{ key: "Ȫ", replace: "O" },
	{ key: "Ȭ", replace: "O" },
	{ key: "Ȯ", replace: "O" },
	{ key: "Ȱ", replace: "O" },
	{ key: "Ƥ", replace: "P" },
	{ key: "Ŕ", replace: "R" },
	{ key: "Ŗ", replace: "R" },
	{ key: "Ř", replace: "R" },
	{ key: "Ȑ", replace: "R" },
	{ key: "Ȓ", replace: "R" },
	{ key: "Ɍ", replace: "R" },
	{ key: "Ś", replace: "S" },
	{ key: "Ŝ", replace: "S" },
	{ key: "Ş", replace: "S" },
	{ key: "Š", replace: "S" },
	{ key: "Ș", replace: "S" },
	{ key: "Ţ", replace: "T" },
	{ key: "Ť", replace: "T" },
	{ key: "Ŧ", replace: "T" },
	{ key: "Ƭ", replace: "T" },
	{ key: "Ʈ", replace: "T" },
	{ key: "Ț", replace: "T" },
	{ key: "Ⱦ", replace: "T" },
	{ key: "Ù", replace: "U" },
	{ key: "Ú", replace: "U" },
	{ key: "Û", replace: "U" },
	{ key: "Ü", replace: "U" },
	{ key: "Ũ", replace: "U" },
	{ key: "Ū", replace: "U" },
	{ key: "Ŭ", replace: "U" },
	{ key: "Ů", replace: "U" },
	{ key: "Ű", replace: "U" },
	{ key: "Ų", replace: "U" },
	{ key: "Ư", replace: "U" },
	{ key: "Ǔ", replace: "U" },
	{ key: "Ǖ", replace: "U" },
	{ key: "Ǚ", replace: "U" },
	{ key: "Ǜ", replace: "U" },
	{ key: "Ǘ", replace: "U" },
	{ key: "Ȕ", replace: "U" },
	{ key: "Ȗ", replace: "U" },
	{ key: "Ʉ", replace: "U" },
	{ key: "Ʋ", replace: "V" },
	{ key: "Ŵ", replace: "W" },
	{ key: "Ý", replace: "Y" },
	{ key: "Ŷ", replace: "Y" },
	{ key: "Ÿ", replace: "Y" },
	{ key: "Ƴ", replace: "Y" },
	{ key: "Ȳ", replace: "Y" },
	{ key: "Ɏ", replace: "Y" },
	{ key: "Ź", replace: "Z" },
	{ key: "Ż", replace: "Z" },
	{ key: "Ž", replace: "Z" },
	{ key: "Ƶ", replace: "Z" },
	{ key: "Ȥ", replace: "Z" },
	{ key: "à", replace: "a" },
	{ key: "á", replace: "a" },
	{ key: "â", replace: "a" },
	{ key: "ã", replace: "a" },
	{ key: "ä", replace: "a" },
	{ key: "å", replace: "a" },
	{ key: "ā", replace: "a" },
	{ key: "ă", replace: "a" },
	{ key: "ą", replace: "a" },
	{ key: "ǎ", replace: "a" },
	{ key: "ǟ", replace: "a" },
	{ key: "ǡ", replace: "a" },
	{ key: "ǻ", replace: "a" },
	{ key: "ȁ", replace: "a" },
	{ key: "ȃ", replace: "a" },
	{ key: "ȧ", replace: "a" },
	{ key: "ɓ", replace: "b" },
	{ key: "ƀ", replace: "b" },
	{ key: "ƃ", replace: "b" },
	{ key: "ç", replace: "c" },
	{ key: "ć", replace: "c" },
	{ key: "ĉ", replace: "c" },
	{ key: "ċ", replace: "c" },
	{ key: "č", replace: "c" },
	{ key: "ɕ", replace: "c" },
	{ key: "ƈ", replace: "c" },
	{ key: "ȼ", replace: "c" },
	{ key: "ď", replace: "d" },
	{ key: "đ", replace: "d" },
	{ key: "ȡ", replace: "d" },
	{ key: "ɗ", replace: "d" },
	{ key: "ɖ", replace: "d" },
	{ key: "ƌ", replace: "d" },
	{ key: "è", replace: "e" },
	{ key: "é", replace: "e" },
	{ key: "ê", replace: "e" },
	{ key: "ë", replace: "e" },
	{ key: "ē", replace: "e" },
	{ key: "ĕ", replace: "e" },
	{ key: "ė", replace: "e" },
	{ key: "ę", replace: "e" },
	{ key: "ě", replace: "e" },
	{ key: "ȩ", replace: "e" },
	{ key: "ȅ", replace: "e" },
	{ key: "ȇ", replace: "e" },
	{ key: "ɇ", replace: "e" },
	{ key: "ƒ", replace: "f" },
	{ key: "ĝ", replace: "g" },
	{ key: "ğ", replace: "g" },
	{ key: "ġ", replace: "g" },
	{ key: "ģ", replace: "g" },
	{ key: "ǵ", replace: "g" },
	{ key: "ǧ", replace: "g" },
	{ key: "ɠ", replace: "g" },
	{ key: "ǥ", replace: "g" },
	{ key: "ĥ", replace: "h" },
	{ key: "ħ", replace: "h" },
	{ key: "ȟ", replace: "h" },
	{ key: "ɦ", replace: "h" },
	{ key: "ì", replace: "i" },
	{ key: "í", replace: "i" },
	{ key: "î", replace: "i" },
	{ key: "ï", replace: "i" },
	{ key: "ĩ", replace: "i" },
	{ key: "ī", replace: "i" },
	{ key: "ĭ", replace: "i" },
	{ key: "į", replace: "i" },
	{ key: "ǐ", replace: "i" },
	{ key: "ȉ", replace: "i" },
	{ key: "ȋ", replace: "i" },
	{ key: "ɨ", replace: "i" },
	{ key: "ĵ", replace: "j" },
	{ key: "ǰ", replace: "j" },
	{ key: "ʝ", replace: "j" },
	{ key: "ɉ", replace: "j" },
	{ key: "ķ", replace: "k" },
	{ key: "ĸ", replace: "k" },
	{ key: "ǩ", replace: "k" },
	{ key: "ƙ", replace: "k" },
	{ key: "ĺ", replace: "l" },
	{ key: "ļ", replace: "l" },
	{ key: "ľ", replace: "l" },
	{ key: "ŀ", replace: "l" },
	{ key: "ł", replace: "l" },
	{ key: "ƚ", replace: "l" },
	{ key: "ɬ", replace: "l" },
	{ key: "ȴ", replace: "l" },
	{ key: "ɫ", replace: "l" },
	{ key: "ɭ", replace: "l" },
	{ key: "ɱ", replace: "m" },
	{ key: "ñ", replace: "n" },
	{ key: "ń", replace: "n" },
	{ key: "ņ", replace: "n" },
	{ key: "ň", replace: "n" },
	{ key: "ŉ", replace: "n" },
	{ key: "ȵ", replace: "n" },
	{ key: "ǹ", replace: "n" },
	{ key: "ɲ", replace: "n" },
	{ key: "ƞ", replace: "n" },
	{ key: "ɳ", replace: "n" },
	{ key: "ò", replace: "o" },
	{ key: "ó", replace: "o" },
	{ key: "ô", replace: "o" },
	{ key: "õ", replace: "o" },
	{ key: "ö", replace: "o" },
	{ key: "ø", replace: "o" },
	{ key: "ō", replace: "o" },
	{ key: "ŏ", replace: "o" },
	{ key: "ő", replace: "o" },
	{ key: "ǒ", replace: "o" },
	{ key: "ȫ", replace: "o" },
	{ key: "ȯ", replace: "o" },
	{ key: "ȱ", replace: "o" },
	{ key: "ȍ", replace: "o" },
	{ key: "ơ", replace: "o" },
	{ key: "ȏ", replace: "o" },
	{ key: "ǫ", replace: "o" },
	{ key: "ǭ", replace: "o" },
	{ key: "ǿ", replace: "o" },
	{ key: "ȭ", replace: "o" },
	{ key: "ƥ", replace: "p" },
	{ key: "ʠ", replace: "q" },
	{ key: "ɋ", replace: "q" },
	{ key: "ŕ", replace: "r" },
	{ key: "ř", replace: "r" },
	{ key: "ŗ", replace: "r" },
	{ key: "ȑ", replace: "r" },
	{ key: "ɾ", replace: "r" },
	{ key: "ȓ", replace: "r" },
	{ key: "ɼ", replace: "r" },
	{ key: "ɍ", replace: "r" },
	{ key: "ɽ", replace: "r" },
	{ key: "ś", replace: "s" },
	{ key: "ŝ", replace: "s" },
	{ key: "ş", replace: "s" },
	{ key: "š", replace: "s" },
	{ key: "ș", replace: "s" },
	{ key: "ʂ", replace: "s" },
	{ key: "ȿ", replace: "s" },
	{ key: "ţ", replace: "t" },
	{ key: "ť", replace: "t" },
	{ key: "ŧ", replace: "t" },
	{ key: "ț", replace: "t" },
	{ key: "ȶ", replace: "t" },
	{ key: "ƭ", replace: "t" },
	{ key: "ƫ", replace: "t" },
	{ key: "ʈ", replace: "t" },
	{ key: "ù", replace: "u" },
	{ key: "ú", replace: "u" },
	{ key: "û", replace: "u" },
	{ key: "ü", replace: "u" },
	{ key: "ũ", replace: "u" },
	{ key: "ū", replace: "u" },
	{ key: "ŭ", replace: "u" },
	{ key: "ů", replace: "u" },
	{ key: "ű", replace: "u" },
	{ key: "ų", replace: "u" },
	{ key: "ʉ", replace: "u" },
	{ key: "ǔ", replace: "u" },
	{ key: "ǘ", replace: "u" },
	{ key: "ǚ", replace: "u" },
	{ key: "ǜ", replace: "u" },
	{ key: "ǖ", replace: "u" },
	{ key: "ȕ", replace: "u" },
	{ key: "ư", replace: "u" },
	{ key: "ȗ", replace: "u" },
	{ key: "ʋ", replace: "v" },
	{ key: "ŵ", replace: "w" },
	{ key: "×", replace: "x" },
	{ key: "ý", replace: "y" },
	{ key: "ÿ", replace: "y" },
	{ key: "ŷ", replace: "y" },
	{ key: "ƴ", replace: "y" },
	{ key: "ȳ", replace: "y" },
	{ key: "ɏ", replace: "y" },
	{ key: "ź", replace: "z" },
	{ key: "ż", replace: "z" },
	{ key: "ž", replace: "z" },
	{ key: "ʑ", replace: "z" },
	{ key: "ȥ", replace: "z" },
	{ key: "ʐ", replace: "z" },
	{ key: "ƶ", replace: "z" },
	{ key: "ɀ", replace: "z" },
	{ key: "Æ", replace: "AE" },
	{ key: "Ǽ", replace: "AE" },
	{ key: "Ǣ", replace: "AE" },
	{ key: "ǲ", replace: "Dz" },
	{ key: "ǅ", replace: "Dz" },
	{ key: "Ǳ", replace: "DZ" },
	{ key: "Ǆ", replace: "DZ" },
	{ key: "Ŋ", replace: "NG" },
	{ key: "Ð", replace: "TH" },
	{ key: "ǈ", replace: "Lj" },
	{ key: "Ǉ", replace: "LJ" },
	{ key: "ǋ", replace: "Nj" },
	{ key: "Ǌ", replace: "NJ" },
	{ key: "Ƣ", replace: "OI" },
	{ key: "Ȣ", replace: "OU" },
	{ key: "Þ", replace: "TH" },
	{ key: "Ĳ", replace: "IJ" },
	{ key: "Œ", replace: "OE" },
	{ key: "æ", replace: "ae" },
	{ key: "ǽ", replace: "ae" },
	{ key: "ǣ", replace: "ae" },
	{ key: "ȸ", replace: "db" },
	{ key: "ǳ", replace: "dz" },
	{ key: "ʣ", replace: "dz" },
	{ key: "ʥ", replace: "dz" },
	{ key: "ǆ", replace: "dz" },
	{ key: "Ŋ", replace: "NG" },
	{ key: "Ð", replace: "TH" },
	{ key: "ǈ", replace: "Lj" },
	{ key: "Ǉ", replace: "LJ" },
	{ key: "ǋ", replace: "Nj" },
	{ key: "Ǌ", replace: "NJ" },
	{ key: "Ƣ", replace: "OI" },
	{ key: "Ȣ", replace: "OU" },
	{ key: "Þ", replace: "TH" },
	{ key: "Ĳ", replace: "IJ" },
	{ key: "Œ", replace: "OE" },
	{ key: "æ", replace: "ae" },
	{ key: "ǽ", replace: "ae" },
	{ key: "ǣ", replace: "ae" },
	{ key: "ȸ", replace: "db" },
	{ key: "ǳ", replace: "dz" },
	{ key: "ʣ", replace: "dz" },
	{ key: "ʥ", replace: "dz" },
	{ key: "ǆ", replace: "dz" },
	{ key: "ŋ", replace: "ng" },
	{ key: "ð", replace: "th" },
	{ key: "ʩ", replace: "fng" },
	{ key: "ɧ", replace: "hng" },
	{ key: "ƕ", replace: "hv" },
	{ key: "ǉ", replace: "lj" },
	{ key: "ʪ", replace: "ls" },
	{ key: "ʫ", replace: "lz" },
	{ key: "ǌ", replace: "nj" },
	{ key: "ƣ", replace: "oi" },
	{ key: "ȣ", replace: "ou" },
	{ key: "ȹ", replace: "qp" },
	{ key: "ß", replace: "ss" },
	{ key: "ʨ", replace: "tc" },
	{ key: "þ", replace: "th" },
	{ key: "ʦ", replace: "ts" },
	{ key: "ĳ", replace: "ij" },
	{ key: "œ", replace: "oe" }
];
