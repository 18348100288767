import { Box, Typography, alpha } from "@mui/material";
import { memo, useMemo, useRef } from "react";
import ScrollSpy from "react-ui-scrollspy";
import { ModalViewName } from "../../../constants";
import { Cart } from "../../../types/Cart";
import { Category, ItemSelectable } from "../../../types/Menu";
import i18n from "../../../utils/i18n/i18n";
import { tCategory } from "../../../utils/i18nMenu";
import { useTheme } from "../../Theme/ThemeWrapper";
import "./ItemsCategoriesList.scss";
import ItemsCategory from "./ItemsCategory";

interface ItemsCategoriesListSingleCategoryProps {
	categories: Category[];
	cart: Cart;
	onNewCartItem: (itemSelectable: ItemSelectable, openDetail?: boolean, referral?: ModalViewName) => void;
	decreaseItemCart: (itemSelectable: ItemSelectable) => void;
}

const ItemsCategoriesListSingleCategory = ({ categories, cart, onNewCartItem, decreaseItemCart }: ItemsCategoriesListSingleCategoryProps) => {
	const { theme, settings } = useTheme();
	const parentScrollContainerRef = useRef<HTMLDivElement | null>(null);

	const bgFaderColor: string = useMemo(
		() =>
			`linear-gradient(0deg, ${alpha(theme.customTheme.palette.background.default, 1)} 0%, ${alpha(
				theme.customTheme.palette.background.default,
				0
			)} 100%)`,
		[theme.customTheme.palette.background.default]
	);

	const categoryLabel = useMemo(() => tCategory(i18n.language, categories[0]), [categories]);

	return (
		<>
			<Typography className="categoryTitleSingle" variant="h1" color={"primary"}>
				{categoryLabel}
			</Typography>
			<Box className="menuItemList" ref={parentScrollContainerRef}>
				<ScrollSpy scrollThrottle={100} useBoxMethod={true} parentScrollContainerRef={parentScrollContainerRef} activeClass="ss-active-demo-2">
					<Box id="init"></Box>
					{categories.map((category, categoryIndex) => {
						return (
							<div key={`${categoryIndex}_catCode_${category.title}`}>
								{category.children.map((categoryChild, categoryChildIndex, children) => {
									return (
										<ItemsCategory
											key={`${categoryIndex}_${categoryChildIndex}_catCode_${categoryChild.categoryCode}`}
											categoryChild={categoryChild}
											categorySiblings={children.length}
											hideDivider={settings.singleCategory && categoryIndex === 0 && categoryChildIndex === 0}
											cart={cart}
											onNewCartItem={onNewCartItem}
											decreaseItemCart={decreaseItemCart}
										/>
									);
								})}
							</div>
						);
					})}
				</ScrollSpy>
				<Box className="verticalFiller" />
				<Box className="verticalFader" sx={{ background: bgFaderColor }} />
			</Box>
		</>
	);
};

export default memo(ItemsCategoriesListSingleCategory);
