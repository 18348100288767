import { default as i18n } from "i18next";
import { CheckoutMethod, getPaymentMethodName, hasStripePaymentMethods, isValidRevenueService, ItemInfoTypology, PaymentMethod, Rt } from "../../constants";
import { emptyStringChar } from "../../constants/defaults";
import { Cart, CartItem, ItemInfo, Order, PaymentInfo, PrintingItem, PrintingQueue, RestaurantInfo, SettingsConfig, Vat } from "../../types";
import { tItemInfo } from "../../utils/i18nMenu";
import { centsToValue } from "../../utils/Numbers";
import { OrderRequestManager } from "../../utils/OrderRequestManager";
import { asciiSafeConverter, hexEncode, trimAfterRoundBracket } from "../../utils/Strings";

const columns: number = 48;
const columnsShort: number = 40;

export abstract class ReceiptRows {
	cart: Cart;
	order: Order;
	restaurantInfo: RestaurantInfo;
	settings: SettingsConfig;
	printDigitalReceipt: boolean;
	printNonFiscalQRCode: boolean;
	printVariationsSplit: boolean;
	receiptTallonMode: boolean;
	skipTallonRecap: boolean;
	vatAssociations: Vat[];
	orderId: string;
	shortenOrder: string;
	showPlaceNumber: boolean;
	columns: number;
	columnsShort: number;
	isNfPrinter: boolean;
	isAsciiPrinter: boolean;
	lang: string;

	constructor(cart: Cart, order: Order, restaurantInfo: RestaurantInfo, settings: SettingsConfig, lang: string) {
		this.cart = cart;
		this.order = order;
		this.restaurantInfo = restaurantInfo;
		this.settings = settings;
		this.printDigitalReceipt = settings.printDigitalReceipt;
		this.printNonFiscalQRCode = settings.printNonFiscalQRCode;
		this.printVariationsSplit = settings.printVariationsSplit;
		this.receiptTallonMode = settings.receiptTallonMode;
		this.skipTallonRecap = settings.skipTallonRecap;
		this.vatAssociations = settings.vatAssociations;
		this.orderId = "4ORDER_" + order.paymentInfo.order_id;
		this.shortenOrder = OrderRequestManager.getServerOrderIdShortened(order);
		this.showPlaceNumber = order.checkoutMethod === CheckoutMethod.PLACE_NUMBER && !settings.skipPlaceNumberChoice;
		this.columns = columns;
		this.columnsShort = columnsShort;
		this.isNfPrinter = !isValidRevenueService(settings.cashSystemRevenueServiceType);
		this.isAsciiPrinter = true; // TODO maybe NF printer can handle non ascii char - how to send 2 bytes characters ?
		this.lang = lang;
	}

	static write(command: Rt.Command, blocks: string[] = []): string {
		return Rt.Row.instruction + command + blocks.join("");
	}

	// TEMP removed comments due to buffer limit
	// static comment(text: string): string {
	// 	return Rt.Row.comment + text;
	// }

	static addBlock(block: Rt.Block, info: string): string {
		return Rt.Block.separator + block + info;
	}

	static spaceFiller(text: string): string {
		return text + " ".repeat(columns - text.length);
	}

	freeNotes(notes: string[], fontSize: Rt.FontSize = Rt.FontSize.normal): string[] {
		const fontSizeCommand = Rt.Block.nonFiscalBody + fontSize;
		const maxColumns = this.columns;

		return notes
			.filter((note) => note !== "")
			.map((note) => {
				const limitedNote = note.substring(0, maxColumns);
				return (
					Rt.Row.instruction +
					Rt.Command.nonFiscal +
					Rt.Block.separator +
					fontSizeCommand +
					limitedNote.padEnd(maxColumns, " ") +
					Rt.Block.descriptionClose
				);
			});
	}

	graphicNotes(
		notes: string[],
		dim: Rt.Graphic.Dim,
		// fontCase: Rt.FontSize = Rt.FontSize.normal,
		weight: Rt.Graphic.Weight = Rt.Graphic.Weight.regular,
		colors: Rt.Graphic.Colors = Rt.Graphic.Colors.blackOnWhite,
		decoration: Rt.Graphic.Decoration = Rt.Graphic.Decoration.none,
		align: "left" | "center" = "center"
	): string[] {
		const graphicCommand = Rt.Block.nonFiscalGraphicBody + Rt.FontSize.large + dim + colors + weight + decoration + "000";
		const singleCharLength = Rt.Graphic.getDimWidth(dim);
		const maxColumns = Math.floor(this.columns / singleCharLength);

		return notes
			.filter((note) => note !== "")
			.map((note) => {
				const limitedNote = note.substring(0, maxColumns);
				const leftNote = limitedNote.padEnd(maxColumns, " ");
				const centeredNote = (" ".repeat(Math.ceil((maxColumns - limitedNote.length) / 2)) + limitedNote).padEnd(maxColumns, " ");
				return (
					Rt.Row.instruction +
					Rt.Command.nonFiscal +
					Rt.Block.separator +
					graphicCommand +
					(align === "left" ? leftNote : centeredNote) +
					Rt.Block.descriptionClose
				);
			});
	}

	/** Pimped t function. translates and converts into Ascii safe string if needed  */
	t(str: string): string {
		const translation = i18n.t(str);
		return this.isAsciiPrinter ? asciiSafeConverter(translation) : translation;
	}

	abstract generateReceipt(): PrintingItem;

	generateAllTallons(): PrintingQueue {
		const isFiscalInstance = this.constructor.name === "ReceiptRowsFiscal";
		const printingList: PrintingQueue = { queue: [] };

		if (this.receiptTallonMode) {
			printingList.queue.push(...this.generateTallons().queue);
		}

		if (isFiscalInstance) {
			const rePrint = new ReceiptRowsNotFiscal(this.cart, this.order, this.restaurantInfo, this.settings, this.lang);
			printingList.queue.push(rePrint.generateReceipt());
		} else {
			if (!this.skipTallonRecap) printingList.queue.push(this.generateReceipt());
		}

		return printingList;
	}

	generateTallons(): PrintingQueue {
		const tallonList: PrintingQueue = { queue: [] };

		this.cart.items?.forEach((item: CartItem) => {
			const itemInfo: ItemInfo = item.item.itemInfo;
			const shortTextOrig = tItemInfo(this.lang, itemInfo, "shortText");
			const shortTextAsciiSafe = tItemInfo(this.lang, itemInfo, "shortTextAsciiSafe");
			const shortText: string = this.isAsciiPrinter ? (shortTextAsciiSafe ?? shortTextOrig) : shortTextOrig;

			const graphicDim: Rt.Graphic.Dim = shortText.length > this.columns / 3 ? Rt.Graphic.Dim.W2H4 : Rt.Graphic.Dim.W3H4;
			const date = new Date();

			for (let j = 0; j < item.quantity; j++) {
				const tallonHeader = this.isNfPrinter
					? [
							...this.graphicNotes(
								[
									(this.restaurantInfo.restaurant_name ?? "").padEnd(this.columns, " "),
									" ".repeat(this.columns),
									("-".repeat(Math.ceil((this.columns - this.t("bill.pickup").length) / 2)) + this.t("bill.pickup")).padEnd(
										this.columns,
										"-"
									),
									" ".repeat(this.columns)
								],
								Rt.Graphic.Dim.W1H2
							),
							...this.graphicNotes([shortText.padEnd(this.columns, " ")], graphicDim, Rt.Graphic.Weight.bold)
						]
					: this.freeNotes(
							[
								("-".repeat(Math.ceil((this.columns - this.t("bill.pickup").length) / 2)) + this.t("bill.pickup")).padEnd(this.columns, "-"),
								" ".repeat(this.columns),
								shortText.padEnd(this.columns, " ")
							],
							Rt.FontSize.extraLarge
						);

				const tallonFooter = this.isNfPrinter
					? this.graphicNotes(
							[
								" ".repeat(this.columns),
								"-".repeat(this.columns),
								" ".repeat(this.columns),
								date.toLocaleString(this.lang + "-" + this.restaurantInfo.country).padEnd(this.columns, " ")
							],
							Rt.Graphic.Dim.W1H2
						)
					: this.freeNotes([" ".repeat(this.columns), "-".repeat(this.columns)], Rt.FontSize.extraLarge);

				const tallonRows: PrintingItem = {
					commandsArray: [
						// ReceiptRows.comment("Clean and close pending receipts in any"),
						ReceiptRows.write(Rt.Command.cleanAndCloseReceipt),
						// ReceiptRows.comment("Start Non-Fiscal doc."),
						ReceiptRows.write(Rt.Command.nonFiscal, [ReceiptRows.addBlock(Rt.Block.nonFiscalInitNoHeader, "")]),

						// ReceiptRows.comment("Item " + itemInfo.tipology + ": " + itemInfo.uid),
						...tallonHeader
					]
				};

				item.options?.forEach((option: CartItem) => {
					const optionInfo: ItemInfo = option.item.itemInfo;
					const optionShortTextOrig = tItemInfo(this.lang, optionInfo, "shortText");
					const optionShortTextAsciiSafe = tItemInfo(this.lang, optionInfo, "shortTextAsciiSafe");
					const optionShortText: string = this.isAsciiPrinter ? (optionShortTextAsciiSafe ?? optionShortTextOrig) : optionShortTextOrig;

					// tallonRows.commandsArray.push(ReceiptRows.comment("Variation " + optionInfo.tipology + ": " + optionInfo.uid));
					for (let i = 0; i < option.quantity; i++) {
						tallonRows.commandsArray.push(...this.freeNotes(["   " + ("(" + optionShortText + ")").padEnd(this.columns - 3, " ")]));
					}
				});

				tallonRows.commandsArray.push(...tallonFooter);

				tallonList.queue.push(tallonRows);
			}
		});

		return tallonList;
	}

	getPaymentRows(): string[] {
		const info: PaymentInfo = this.order.paymentInfo;

		if (!info.transaction_id) return [];

		const rows: string[] = [this.t("bill.transaction_data") + ":", "ID: " + info.transaction_id];

		if (hasStripePaymentMethods([this.order.paymentMethod])) {
			if (info.card_brand) rows.push(this.t("bill.card_brand") + ": " + info.card_brand);
			if (info.card_number) rows.push(this.t("bill.card_number") + ": " + info.card_number);
		}

		if (this.order.paymentMethod === PaymentMethod.SATISPAY) {
			if (Boolean(info.meal_voucher_amount) === true && Boolean(info.meal_voucher_number) === true) {
				const singleVoucherAmount: string = centsToValue(Number(info.meal_voucher_amount) / Number(info.meal_voucher_number))
					.toFixed(2)
					.toString();
				const vouchersAmount: string = centsToValue(Number(info.meal_voucher_amount)).toFixed(2).toString();
				rows.push(
					this.t("bill.voucher") + ": " + info.meal_voucher_number + "x" + singleVoucherAmount + " " + vouchersAmount + this.restaurantInfo.currency
				);
			}
		}

		return rows;
	}
}

export class ReceiptRowsFiscal extends ReceiptRows {
	generateReceipt(): PrintingItem {
		const contentLength: number = 8;
		const starredEmptyPrefix: string = " ".repeat(8);
		const starredPrefix: string = "*  ";
		const starredSuffix: string = "  *";
		const starredLabelLength: number = this.columns - 8 - contentLength - starredEmptyPrefix.length - starredPrefix.length - starredSuffix.length; // 18
		const starredGraphicRow: string = starredEmptyPrefix + "*".repeat(this.columns - 8 - starredEmptyPrefix.length); // 32

		const orderLabel: string = this.t("bill.order").substring(0, starredLabelLength).padEnd(starredLabelLength, " ");
		const placeLabel: string = this.t("bill.place").substring(0, starredLabelLength).padEnd(starredLabelLength, " ");

		const order: string = this.shortenOrder.padStart(contentLength, " ");
		const place: string = this.order.placeNumber.padStart(contentLength, " ");

		const makeDocumentCommand: Rt.Command = this.receiptTallonMode
			? Rt.Command.makeDocumentDigital
			: this.printDigitalReceipt
				? Rt.Command.makeDocumentBoth
				: Rt.Command.makeDocumentPaper;
		const makeDocumentValue = this.printDigitalReceipt ? "1" : "0";
		const makeDocumentRow: string = ReceiptRows.write(makeDocumentCommand, [
			ReceiptRows.addBlock(Rt.Block.value, makeDocumentValue),
			ReceiptRows.addBlock(Rt.Block.value2, makeDocumentValue)
		]);

		return {
			commandsArray: [
				// ReceiptRows.comment("Clean and close pending receipts in any"),
				ReceiptRows.write(Rt.Command.cleanAndCloseReceipt),

				// ReceiptRows.comment("Body"),
				...this.partBodyFiscalReceipt(),

				// ReceiptRows.comment("Payment data"),
				...this.freeNotesFiscal(this.getPaymentRows()),

				// ReceiptRows.comment("Receipt Footer with OrderId and possibly place number"),
				...this.freeNotesFiscal(
					[
						starredGraphicRow,
						starredEmptyPrefix + starredPrefix + orderLabel + order + starredSuffix,
						this.showPlaceNumber ? starredEmptyPrefix + starredPrefix + placeLabel + place + starredSuffix : "",
						starredGraphicRow
					],
					Rt.FontSize.large,
					true
				),

				// ReceiptRows.comment("Generate digital receipt"),
				makeDocumentRow,

				// ReceiptRows.comment("Payment method"),
				ReceiptRows.write(Rt.Command.payment, [
					ReceiptRows.addBlock(
						Rt.Block.descriptionOpen,
						trimAfterRoundBracket(this.t(`checkout.paymentModal.type.${getPaymentMethodName(this.order.paymentMethod)}`)) +
							Rt.Block.descriptionClose
					)
				]),

				// ReceiptRows.comment("Close pending receipts in any"),
				ReceiptRows.write(Rt.Command.cleanAndCloseReceipt)
			]
		};
	}

	partBodyFiscalReceipt(): string[] {
		const commands: string[] = [];

		this.cart.items?.forEach((cartItem: CartItem) => {
			const itemInfo: ItemInfo = cartItem.item.itemInfo;
			const shortTextOrig = tItemInfo(this.lang, itemInfo, "shortText");
			const shortTextAsciiSafe = tItemInfo(this.lang, itemInfo, "shortTextAsciiSafe");
			const shortText: string = this.isAsciiPrinter ? (shortTextAsciiSafe ?? shortTextOrig) : shortTextOrig;

			const hasDiscounts: boolean = cartItem.options?.some((option: CartItem) => {
				const item: ItemInfo = option.item.itemInfo;
				return (
					(item.tipology === ItemInfoTypology.cond_sub && Number(item.price) !== 0) ||
					(item.tipology === ItemInfoTypology.plu && Number(item.price) < 0)
				);
			});
			const hasSurcharges: boolean = cartItem.options?.some((option: CartItem) => {
				const item: ItemInfo = option.item.itemInfo;
				return (
					(item.tipology === ItemInfoTypology.cond_add && Number(item.price) !== 0) ||
					(item.tipology === ItemInfoTypology.plu && Number(item.price) > 0)
				);
			});
			const hasMixedVariations: boolean = hasSurcharges && hasDiscounts;
			const actuallyPrintVariationsSplit: boolean = this.printVariationsSplit && !hasMixedVariations;

			commands.push(
				// ReceiptRows.comment("Item " + itemInfo.tipology + ": " + itemInfo.uid),
				ReceiptRows.write(Rt.Command.department, [
					this.getVatById(itemInfo.vatRef),
					ReceiptRows.addBlock(Rt.Block.value, actuallyPrintVariationsSplit ? itemInfo.price.toString() : cartItem.resultingPrice.toString()),
					ReceiptRows.addBlock(Rt.Block.descriptionOpen, shortText + Rt.Block.descriptionClose),
					cartItem.quantity > 1 ? ReceiptRows.addBlock(Rt.Block.quantity, cartItem.quantity.toString()) : ""
				])
			);
			cartItem.options?.forEach((option: CartItem) => {
				const optionInfo: ItemInfo = option.item.itemInfo;
				const optionShortTextOrig = tItemInfo(this.lang, optionInfo, "shortText");
				const optionShortTextAsciiSafe = tItemInfo(this.lang, optionInfo, "shortTextAsciiSafe");
				const optionShortText: string = this.isAsciiPrinter ? (optionShortTextAsciiSafe ?? optionShortTextOrig) : optionShortTextOrig;
				const isDiscount: boolean =
					(optionInfo.tipology === ItemInfoTypology.cond_sub && Number(optionInfo.price) !== 0) ||
					(optionInfo.tipology === ItemInfoTypology.plu && Number(optionInfo.price) < 0);
				const isSurcharge: boolean =
					(optionInfo.tipology === ItemInfoTypology.cond_add && Number(optionInfo.price) !== 0) ||
					(optionInfo.tipology === ItemInfoTypology.plu && Number(optionInfo.price) > 0);
				const printCurrentVariation: boolean = (isDiscount || isSurcharge) && actuallyPrintVariationsSplit;
				const signBlock: Rt.Block = isDiscount ? Rt.Block.discount : Rt.Block.surcharge;

				// commands.push(ReceiptRows.comment("Variation " + optionInfo.tipology + ": " + optionInfo.uid));
				for (let i = 0; i < option.quantity; i++) {
					commands.push(
						ReceiptRows.write(printCurrentVariation ? Rt.Command.variation : Rt.Command.variationNote, [
							printCurrentVariation
								? signBlock + ReceiptRows.addBlock(Rt.Block.value, (optionInfo.price * cartItem.quantity).toString())
								: ReceiptRows.addBlock(Rt.Block.empty, ""),
							ReceiptRows.addBlock(
								Rt.Block.descriptionOpen,
								Rt.Block.variationPadding +
									this.t(`checkout.orderReview.variation.${optionInfo.tipology}`).toUpperCase() +
									" " +
									optionShortText +
									Rt.Block.descriptionClose
							)
						])
					);
				}
			});
		});

		return commands;
	}

	getVatById(id: string): string {
		const vatList: Vat[] = this.vatAssociations;
		const chosenVat: Vat | undefined = vatList.find((vat: Vat) => vat.id === id);
		return chosenVat?.cashDepartment ?? "";
	}

	freeNotesFiscal(notes: string[], fontSize: Rt.FontSize = Rt.FontSize.normal, isShortNote = false): string[] {
		const fontSizeCommand = fontSize.padStart(2, "0");
		const freeNoteCommand = isShortNote ? Rt.Command.freeNote40 : Rt.Command.freeNote48;
		const maxColumns = isShortNote ? this.columnsShort : this.columns;
		return notes
			.filter((note) => note !== "")
			.map((note, index) => {
				const indexString = (index + 1).toString().padStart(2, "0");
				const limitedNote = note.substring(0, maxColumns);
				return Rt.Row.instruction + freeNoteCommand + indexString + fontSizeCommand + limitedNote.padEnd(maxColumns, " ") + Rt.Block.descriptionClose;
			});
	}
}

export class ReceiptRowsNotFiscal extends ReceiptRows {
	generateReceipt(): PrintingItem {
		const emptyRow: string = " ".repeat(this.columns);

		const contentLength: number = 24;
		const labelLength: number = this.columns - contentLength; // 24
		const starredPrefix: string = " *  ";
		const starredSuffix: string = "  * ";
		const starredLabelLength: number = labelLength - starredPrefix.length - starredSuffix.length; // 16
		const starredGraphicRow: string = " " + "*".repeat(this.columns - 2) + " ";

		const amountLabel: string = this.t("bill.amount").substring(0, labelLength).padEnd(labelLength, " ");
		const orderLabel: string = this.t("bill.order").substring(0, starredLabelLength).padEnd(starredLabelLength, " ");
		const placeLabel: string = this.t("bill.place").substring(0, starredLabelLength).padEnd(starredLabelLength, " ");

		const amount: string = centsToValue(this.cart.amount).toFixed(2).toString().padStart(contentLength, " ");
		const order: string = this.shortenOrder.padStart(contentLength, " ");
		const place: string = this.order.placeNumber.padStart(contentLength, " ");
		const placeRowSimple: string = this.t("bill.place") + ": " + this.order.placeNumber;

		const orderRows = this.isNfPrinter
			? [
					...this.graphicNotes(["-".repeat(this.columns), this.t("bill.order")], Rt.Graphic.Dim.W1H2),
					...this.graphicNotes([this.shortenOrder], Rt.Graphic.Dim.W4H4, Rt.Graphic.Weight.bold),
					...this.graphicNotes(["-".repeat(this.columns)], Rt.Graphic.Dim.W1H2),
					...this.freeNotes([this.showPlaceNumber ? placeRowSimple : ""])
				]
			: this.freeNotes(
					[
						starredGraphicRow,
						starredPrefix + orderLabel + order + starredSuffix,
						this.showPlaceNumber ? starredPrefix + placeLabel + place + starredSuffix : "",
						starredGraphicRow
					],
					Rt.FontSize.large
				);

		return {
			commandsArray: [
				// ReceiptRows.comment("Clean and close pending receipts in any"),
				ReceiptRows.write(Rt.Command.cleanAndCloseReceipt),

				// ReceiptRows.comment("Start Non-Fiscal doc."),
				ReceiptRows.write(Rt.Command.nonFiscal, [ReceiptRows.addBlock(Rt.Block.nonFiscalInit, "")]),

				// ReceiptRows.comment("Body"),
				...this.partBodyNonFiscalReceipt(),

				// ReceiptRows.comment("Non-Fiscal Amount"),
				...this.freeNotes([emptyRow, amountLabel + amount, emptyRow]),

				// ReceiptRows.comment("Receipt Footer with OrderId and possibly place number"),
				...orderRows,

				// ReceiptRows.comment("Payment data"),
				...this.freeNotes(this.getPaymentRows()),

				// ReceiptRows.comment("QRcode of the full Order Id: " + this.orderId),
				this.printNonFiscalQRCode
					? ReceiptRows.write(Rt.Command.generateQR, [
							ReceiptRows.addBlock(Rt.Block.value, "1"),
							ReceiptRows.addBlock(Rt.Block.descriptionOpen, hexEncode(this.orderId) + Rt.Block.descriptionClose)
						])
					: emptyStringChar,

				// ReceiptRows.comment("End Non-Fiscal doc."),
				ReceiptRows.write(Rt.Command.nonFiscal, [ReceiptRows.addBlock(Rt.Block.nonFiscalEnd, "")])
			]
		};
	}

	partBodyNonFiscalReceipt(): string[] {
		const commands: string[] = [];
		const quantityLabel: string = this.t("bill.max3lettersQuantity");
		const descriptionLabel: string = this.t("bill.description");

		commands.push(...this.freeNotes([this.columnView(quantityLabel, false, descriptionLabel, this.restaurantInfo.currency)]));

		this.cart.items?.forEach((item: CartItem) => {
			const itemInfo: ItemInfo = item.item.itemInfo;
			const shortTextOrig = tItemInfo(this.lang, itemInfo, "shortText");
			const shortTextAsciiSafe = tItemInfo(this.lang, itemInfo, "shortTextAsciiSafe");
			const shortText: string = this.isAsciiPrinter ? (shortTextAsciiSafe ?? shortTextOrig) : shortTextOrig;
			const price: string = centsToValue(itemInfo.price * item.quantity)
				.toFixed(2)
				.toString();

			commands.push(
				// ReceiptRows.comment("Item " + itemInfo.tipology + ": " + itemInfo.uid),
				...this.freeNotes([this.columnView(item.quantity.toString(), true, shortText, price)])
			);
			item.options?.forEach((option: CartItem) => {
				const optionInfo: ItemInfo = option.item.itemInfo;
				const optionShortTextOrig = tItemInfo(this.lang, optionInfo, "shortText");
				const optionShortTextAsciiSafe = tItemInfo(this.lang, optionInfo, "shortTextAsciiSafe");
				const optionShortText: string = this.isAsciiPrinter ? (optionShortTextAsciiSafe ?? optionShortTextOrig) : optionShortTextOrig;
				const multipliedPrice: number = optionInfo.price * item.quantity;
				const sign = optionInfo.tipology === ItemInfoTypology.cond_sub ? "-" : "";
				const multipliedPriceText: string =
					multipliedPrice === 0
						? ""
						: sign +
							centsToValue(optionInfo.price * item.quantity)
								.toFixed(2)
								.toString();

				const description: string = this.t(`checkout.orderReview.variation.${optionInfo.tipology}`).toUpperCase() + " " + optionShortText;

				// commands.push(ReceiptRows.comment("Variation " + optionInfo.tipology + ": " + optionInfo.uid));
				for (let i = 0; i < option.quantity; i++) {
					commands.push(...this.freeNotes([this.columnView("", false, description, multipliedPriceText)]));
				}
			});
		});

		return commands;
	}

	columnView(col_quantity: string, hasTimes: boolean = false, col_desc: string, col_price: string): string {
		const colQuantityLen: number = 4;
		const colTimesLen: number = 3;
		const colPriceLen: number = 9;
		const colDescLen: number = this.columns - colQuantityLen - colTimesLen - colPriceLen;

		const col_times: string = hasTimes ? " x " : " ".repeat(3);

		return [
			col_quantity.substring(0, colQuantityLen).padStart(colQuantityLen, " "),
			col_times,
			col_desc.substring(0, colDescLen).padEnd(colDescLen, " "),
			col_price.substring(0, colPriceLen).padStart(colPriceLen, " ")
		].join("");
	}
}
