import { alpha, Box, Collapse, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { memo, MouseEvent as ReactMouseEvent, useCallback, useMemo } from "react";
import { useCdnAssets } from "../../../hooks/useCdnAssets";
import { Category } from "../../../types";
import { areColorsContrasted, isDarkColor } from "../../../utils/color";
import i18n from "../../../utils/i18n/i18n";
import { tCategory } from "../../../utils/i18nMenu";
import TouchListItemButton from "../../Layout/Buttons/TouchListItemButton";
import { useTheme } from "../../Theme/ThemeWrapper";
import "./Sidebar.scss";
import SidebarChild from "./SidebarItemChild";

interface SidebarItemProps {
	category: Category;
	changeSelectedCategory: (categoryTitle: string) => void;
	changeSelectedCategoryChild: (e: ReactMouseEvent<HTMLDivElement, MouseEvent>, categoryCode: string) => void;
	isSelectedCategory: boolean;
	selectedCategoryChildCode?: string;
}

const SidebarItem = ({ category, changeSelectedCategory, changeSelectedCategoryChild, isSelectedCategory, selectedCategoryChildCode }: SidebarItemProps) => {
	const { theme, settings } = useTheme();
	const { getSystemIconUrl } = useCdnAssets();
	const iconUrl: string = getSystemIconUrl(category.icon);

	const getColors = useCallback((): {
		groupColor: string;
		childColor: string;
		bgIconColor: string;
		fgIconColor: string;
		textShadowMainColor: string | null;
		textShadowChildColor: string | null;
	} => {
		const palette = theme.customTheme.palette;
		const mainColor: string = isSelectedCategory ? palette.primary.main : palette.secondary.main;
		const childColor: string = palette.secondary.main;
		let contrastColor: string = palette.background.default;
		let textShadowMainColor: string | null = "transparent";
		let textShadowChildColor: string | null = "transparent";

		if (!areColorsContrasted(mainColor, contrastColor)) {
			textShadowMainColor = alpha(isDarkColor(mainColor) ? palette.grey[100] : palette.grey[900], 0.7);
			contrastColor = textShadowMainColor;
		}

		if (!areColorsContrasted(childColor, contrastColor)) {
			textShadowChildColor = alpha(isDarkColor(childColor) ? palette.grey[100] : palette.grey[900], 0.7);
		}

		return {
			groupColor: mainColor,
			childColor: childColor,
			bgIconColor: settings.iconOutlineInversed ? contrastColor : mainColor,
			fgIconColor: settings.iconOutlineInversed ? mainColor : contrastColor,
			textShadowMainColor: textShadowMainColor,
			textShadowChildColor: textShadowChildColor
		};
	}, [theme.customTheme.palette, settings.iconOutlineInversed, isSelectedCategory]);

	const colors = useMemo(() => getColors(), [getColors]);

	const categoryLabel = useMemo(() => tCategory(i18n.language, category), [category]);

	const handleChangeSelectedCategory = useCallback(() => {
		if (!isSelectedCategory) {
			changeSelectedCategory(category.title);
		}
	}, [isSelectedCategory, category.title, changeSelectedCategory]);

	return (
		<ListItem className="category" disablePadding>
			<TouchListItemButton onClick={handleChangeSelectedCategory}>
				<ListItemIcon
					className="icon"
					sx={{
						backgroundColor: colors.bgIconColor,
						boxShadow: settings.iconOutlineInversed ? 8 : 2
					}}
				>
					<Box
						className="icon-image"
						sx={{
							backgroundColor: colors.fgIconColor,
							mask: `url(${iconUrl}) no-repeat center / contain`
							// boxShadow: `0 0 1rem 4rem inset ${colors.textShadowChildColor}`
						}}
					></Box>
				</ListItemIcon>
				<ListItemText
					className="groupText"
					primary={<Typography variant="subtitle2">{categoryLabel}</Typography>}
					sx={{
						color: colors.groupColor,
						textShadow: `0 0.0625rem 0.125rem ${colors.textShadowMainColor}`
					}}
				/>

				{category.children.length > 0 ? (
					<Collapse in={isSelectedCategory && category.children.length > 1}>
						<List
							className="childCategory"
							sx={{
								color: colors.childColor,
								textShadow: `0 0.0625rem 0.125rem ${colors.textShadowChildColor}`
							}}
						>
							{category.children.map((categoryChild) => (
								<SidebarChild
									key={categoryChild.categoryCode}
									categoryChild={categoryChild}
									changeSelectedCategoryChild={changeSelectedCategoryChild}
									isSelectedCategoryChildCode={selectedCategoryChildCode === categoryChild.categoryCode}
								/>
							))}
						</List>
					</Collapse>
				) : null}
			</TouchListItemButton>
		</ListItem>
	);
};

export default memo(SidebarItem);
