import { DoubleArrow, HighlightOffRounded } from "@mui/icons-material";
import { Box, LinearProgress, Skeleton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";
import { LogChannel, PaymentMethod, PaymentRequestStatus, stripePaymentMethods } from "../../../constants";
import { defaultPaymentIntentResponse } from "../../../constants/defaults";
import { useCurrency } from "../../../hooks/useCurrency";
import { useLogger } from "../../../hooks/useLogger";
import { ElectronicPayment, ElectronicPaymentSatispay, ElectronicPaymentStripe } from "../../../services/4Delivery";
import { Cart, Order, PaymentInfo, PaymentIntentResponse } from "../../../types";
import TouchButton from "../../Layout/Buttons/TouchButton";
import { useTheme } from "../../Theme/ThemeWrapper";
import ActivateReaderRN from "./PaymentStep/ActivateReaderRN";
import CancelIntent from "./PaymentStep/CancelIntent";
import CheckCashSystem from "./PaymentStep/CheckCashSystem";
import CheckIntent from "./PaymentStep/CheckIntent";
import CheckReaderRN from "./PaymentStep/CheckReaderRN";
import CreateIntent from "./PaymentStep/CreateIntent";
import CreateIntentAndActivateReader from "./PaymentStep/CreateIntentAndActivateReader";
import ConfirmModal from "./Wrappers/ConfirmModal";

interface PaymentModalProps {
	cart: Cart;
	order: Order;
	confirmPayment: (paymentInfo: PaymentInfo, kioskCartId: number | null) => void;
	cancelOrder: () => void;
}

const PaymentModal = ({ cart, order, confirmPayment, cancelOrder }: PaymentModalProps) => {
	const { t } = useTranslation();
	const { currency } = useCurrency();
	const { theme, settings } = useTheme();
	const { log } = useLogger();

	const isOneOFStripePayments: boolean = stripePaymentMethods.includes(order.paymentMethod);
	const isStripePaymentRN: boolean = order.paymentMethod === PaymentMethod.STRIPE_RN;

	const electronicPayment: ElectronicPayment = isOneOFStripePayments
		? new ElectronicPaymentStripe(isStripePaymentRN ? undefined : settings.terminalSerialNumber)
		: new ElectronicPaymentSatispay();

	const [requestStatus, setRequestStatus] = useState<PaymentRequestStatus>(
		settings.isCashSystemEnabled
			? PaymentRequestStatus.checkingCashSystem
			: isStripePaymentRN
				? PaymentRequestStatus.checkingReaderRN
				: PaymentRequestStatus.creatingIntentAndActivateReader
	);
	const [paymentIntent, setPaymentIntent] = useState<PaymentIntentResponse>(defaultPaymentIntentResponse);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isCanceling, setIsCanceling] = useState<boolean>(false);
	const [isConnecting, setConnecting] = useState<boolean>(false);
	const [textWarning, setTextWarning] = useState<string>();

	const resetPaymentIntent = () => setPaymentIntent(defaultPaymentIntentResponse);

	const handleCancelButton = () => setRequestStatus(PaymentRequestStatus.cancelingIntent);

	useEffect(() => {
		log(`${requestStatus}`, LogChannel.payment);
	}, [requestStatus, log]);

	return (
		<>
			<ConfirmModal
				outsideNode={
					isStripePaymentRN && !textWarning && !isCanceling ? (
						<DoubleArrow
							className="embeddedReaderPointer animated"
							sx={{
								fontSize: "20rem",
								color: theme.customTheme.palette.background.default,
								backgroundColor: theme.customTheme.palette.secondary.main,
								borderRadius: "50%",
								transform: "rotate(90deg)"
							}}
						/>
					) : undefined
				}
			>
				<Box>
					{isOneOFStripePayments ? (
						<>
							<Box>
								<Typography color="primary" variant="h4" textAlign="center" sx={{ marginTop: "1rem" }}>
									{isConnecting ? "" : t("checkout.paymentModal.total").toUpperCase() + " " + currency(cart.amount)}
								</Typography>
								<Typography color="default" variant="h4" textAlign="center" sx={{ marginTop: "1rem" }}>
									{textWarning
										? textWarning
										: isCanceling
											? t("checkout.paymentModal.cancelling") + t("common.ellipsis")
											: isConnecting
												? t("checkout.paymentModal.waitPlease") + t("common.ellipsis")
												: t("checkout.paymentModal.instructions").toUpperCase()}
								</Typography>
							</Box>
							{!textWarning && !isCanceling && !isStripePaymentRN && <DoubleArrow sx={{ fontSize: "20rem" }} />}

							<LinearProgress
								sx={{ width: "100%", marginTop: "2rem", paddingTop: ".5rem", opacity: isLoading || isConnecting ? 1 : 0 }}
								color="secondary"
							/>
						</>
					) : (
						<>
							<Box>
								<Typography color="primary" variant="h4" textAlign="center" sx={{ marginTop: "1rem" }}>
									{t("checkout.paymentModal.total").toUpperCase() + " " + currency(cart.amount)}
								</Typography>
								<Typography color="default" variant="h4" textAlign="center" sx={{ marginTop: "1rem" }}>
									{textWarning
										? textWarning
										: isCanceling
											? t("checkout.paymentModal.cancelling")
											: t("checkout.paymentModal.instructionsSatispay").toUpperCase()}
								</Typography>
							</Box>

							{isLoading && (
								<>
									{paymentIntent !== null && paymentIntent.redirect_url ? (
										<QRCode
											size={240}
											fgColor={theme.systemTheme.palette.text.primary}
											bgColor={theme.systemTheme.palette.background.paper}
											value={paymentIntent.redirect_url}
										/>
									) : (
										<Skeleton variant="rectangular" width={240} height={240} />
									)}
								</>
							)}
						</>
					)}
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center"
					}}
				>
					<TouchButton
						color="error"
						variant="contained"
						size="large"
						startIcon={<HighlightOffRounded />}
						onClick={handleCancelButton}
						disabled={paymentIntent.kiosk_cart_id === null || isCanceling}
					>
						<Typography variant="button">{t("common.cancel")}</Typography>
					</TouchButton>
				</Box>
			</ConfirmModal>

			{requestStatus === PaymentRequestStatus.checkingCashSystem && (
				<CheckCashSystem
					isStripePaymentRN={isStripePaymentRN}
					setRequestStatus={setRequestStatus}
					setIsLoading={setIsLoading}
					cancelOrder={cancelOrder}
				/>
			)}
			{requestStatus === PaymentRequestStatus.checkingReaderRN && (
				<CheckReaderRN setRequestStatus={setRequestStatus} setConnecting={setConnecting} cancelOrder={cancelOrder} />
			)}
			{requestStatus === PaymentRequestStatus.creatingIntent && (
				<CreateIntent
					order={order}
					cart={cart}
					electronicPayment={electronicPayment}
					paymentIntent={paymentIntent}
					setRequestStatus={setRequestStatus}
					setIsLoading={setIsLoading}
					setPaymentIntent={setPaymentIntent}
					cancelOrder={cancelOrder}
				/>
			)}
			{requestStatus === PaymentRequestStatus.activateReaderRN && (
				<ActivateReaderRN
					clientSecret={paymentIntent.client_secret ?? ""}
					setRequestStatus={setRequestStatus}
					setIsLoading={setIsLoading}
					cancelOrder={cancelOrder}
				/>
			)}
			{requestStatus === PaymentRequestStatus.creatingIntentAndActivateReader && (
				<CreateIntentAndActivateReader
					order={order}
					cart={cart}
					electronicPayment={electronicPayment}
					paymentIntent={paymentIntent}
					setRequestStatus={setRequestStatus}
					setIsLoading={setIsLoading}
					setPaymentIntent={setPaymentIntent}
					cancelOrder={cancelOrder}
				/>
			)}
			{requestStatus === PaymentRequestStatus.checkingIntent && (
				<CheckIntent
					order={order}
					paymentIntent={paymentIntent}
					electronicPayment={electronicPayment}
					isStripePaymentRN={isStripePaymentRN}
					setRequestStatus={setRequestStatus}
					setIsLoading={setIsLoading}
					confirmPayment={confirmPayment}
				/>
			)}
			{requestStatus === PaymentRequestStatus.cancelingIntent && (
				<CancelIntent
					order={order}
					cart={cart}
					electronicPayment={electronicPayment}
					paymentIntent={paymentIntent}
					isStripePaymentRN={isStripePaymentRN}
					setIsCanceling={setIsCanceling}
					setIsLoading={setIsLoading}
					setTextWarning={setTextWarning}
					resetPaymentIntent={resetPaymentIntent}
					cancelOrder={cancelOrder}
				/>
			)}
		</>
	);
};
export default PaymentModal;
