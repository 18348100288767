import { linkBridge } from "@webview-bridge/web";
import { useMemo } from "react";
import type { AppBridge, AppBridgeState, AvailableRelease } from "../types/reactNative/bridgeOutput"; // Import the type 'appBridge' declared in native

export const initialBridge: AppBridgeState = {
	bridgeActive: false,
	collectUsbPayment: async (piClientSecret: string) => {
		// alert("not support collectUsbPayment: " + piClientSecret);
		return {};
	},
	cancelUsbPayment: async () => {
		// alert("not support cancelUsbPayment");
		return false;
	},
	testUsbStripeReader: async () => {
		// alert("not support testUsbStripeReader");
		return false;
	},
	persistLog: async (value: string) => {
		// alert("not support persistLog: " + value);
	},
	getLogFilesToUpload: async (kioskId: number) => {
		// alert("not support getLogFilesToUpload");
		return [];
	},
	uploadLogFiles: async (kioskId: number, logFiles: string[]) => {
		// alert("not support uploadLogFiles: " + logFiles);
	},
	copyLogFilesToDocuments: async () => {
		// alert("not support copyLogFilesToDocuments");
	},
	changeWebviewUrl: async (url: string, hasCloseButton?: boolean) => {
		// alert("not support changeWebviewUrl: " + url);
	},
	checkUpdate: async (token: string) => {
		// alert("not support checkUpdate");
		return [];
	},
	installUpdate: async (token: string, release: AvailableRelease) => {
		// alert("not support installUpdate");
		return false;
	},
	openAnyDesk: async () => {
		// alert("not support openAnyDesk");
		return false;
	},
	data: {
		stripeLocationId: null,
		isInternetReachable: false,
		username: "",
		password: "",
		webviewUrl: "",
		webviewHasCloseButton: false,
		webviewReferrer: "",
		logServiceActive: true,
		appVersion: "",
		logFileName: undefined
	},
	setStripeLocationId: async (value: string | null) => {
		// alert("not support setStripeLocationId: " + value);
	},
	setInternetReachable: async (value: boolean) => {
		// alert("not support setInternetReachable: " + value);
	},
	setUsername: async (value: string) => {
		// alert("not support setUsername: " + value);
	},
	setPassword: async (value: string) => {
		// alert("not support setPassword: " + value);
	},
	setLogServiceActive: async (value: boolean) => {
		// alert("not support setLogServiceActive: " + value);
	},
	setAppVersion: async (value: string) => {
		// alert("not support setAppVersion: " + value);
	},
	setLogFileName: async (value: string | undefined) => {
		// alert("not support setLogFileName: " + value);
	}
};

export const useBridgeInit = () => {
	const bridge = useMemo(
		() =>
			linkBridge<AppBridge>({
				throwOnError: true,
				initialBridge: initialBridge
			}),
		[]
	);

	/**
	 * Check the bridge is available and it is an actual bridge (non the initial fake one)
	 */
	const isWebViewBridgeAvailable = useMemo((): boolean => {
		return bridge.isWebViewBridgeAvailable && bridge.store.getState().bridgeActive;
	}, [bridge]);

	return {
		bridge,
		isWebViewBridgeAvailable
	};
};
