import { alpha, Box, Grid2 as Grid, ThemeProvider, Typography } from "@mui/material";
import { memo, ReactNode, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ModalViewName } from "../../../constants";
import { useGetRestaurantMedia } from "../../../hooks/useCdnAssets";
import { Cart, CartItem, ItemSelectable } from "../../../types";
import TableWrapper from "../../Layout/Table/TableWrapper";
import { useTheme } from "../../Theme/ThemeWrapper";
import OrderReviewBottomBar from "../BottomBar/OrderReviewBottomBar";
import ItemCard from "../Items/ItemCard";
import "../OrderMaker.scss";
import "./OrderReviewDialog.scss";
import OrderReviewRow from "./OrderReviewRow";
import DialogFull from "./Wrappers/DialogFull";

interface OrderReviewDialogProps {
	cart: Cart;
	addItemToCart: (cartItem: CartItem) => void;
	removeItemFromCart: (uid: string) => void;
	closeModal: () => void;
	confirmOrder: () => void;
	goToItemCartEditor: (cartItem: CartItem) => void;
	recommendedItems: ItemSelectable[];
	onNewCartItem: (itemSelectable: ItemSelectable, openDetail?: boolean, referral?: ModalViewName) => void;
	setIsNewCartItemSelected: (isNewCartItemSelected: boolean) => void;
}

const OrderReviewDialog = ({
	cart,
	addItemToCart,
	removeItemFromCart,
	closeModal,
	confirmOrder,
	goToItemCartEditor,
	recommendedItems,
	onNewCartItem
}: OrderReviewDialogProps) => {
	const { theme } = useTheme();
	const { t } = useTranslation();
	const { url: logoUrl, isFallback: isLogoFallback } = useGetRestaurantMedia("logo");

	const bg = theme.systemTheme.palette.background.default;

	const plus: string[] = useMemo(() => {
		const newPlus: string[] = [];
		cart.items?.forEach((item: CartItem) => {
			if (newPlus.indexOf(item.item.itemInfo.uid) === -1) {
				newPlus.push(item.item.itemInfo.uid);
			}
		});
		return newPlus;
	}, [cart.items]);

	const currentRecommended: ItemSelectable[] = useMemo(
		() => recommendedItems.filter((item: ItemSelectable) => !plus.includes(item.itemInfo.uid)),
		[plus, recommendedItems]
	);

	const onNewCartItemFromSuggested = useCallback(
		(itemSelectable: ItemSelectable, openDetail?: boolean): void => {
			onNewCartItem(itemSelectable, openDetail, ModalViewName.orderReview);
		},
		[onNewCartItem]
	);

	const doNothing = useCallback(() => {}, []);

	return (
		<DialogFull title="orderReviewDialog">
			<Box className="header" sx={{ backgroundColor: bg }}>
				{isLogoFallback ? null : <img className="customLogo" src={logoUrl} alt="" loading="lazy" />}
			</Box>
			<Box className="main orderReview">
				<Box className="titles" sx={{ backgroundColor: bg }}>
					<Typography variant="h3" color="primary">
						{t("checkout.orderReview.confirmOrderText").toUpperCase()}
					</Typography>
					<Typography variant="h4" color="primary">
						{t("checkout.orderReview.summaryText").toUpperCase()}
					</Typography>
				</Box>

				<Box className="scrollable" sx={{ width: "100%", mt: "8vh" }}>
					<TableWrapper sx={{ minHeight: "50vh", margin: "2rem 0 3rem" }}>
						{cart.items?.map((row: CartItem) => {
							const handleClick = () => goToItemCartEditor(row);

							return (
								<OrderReviewRow
									key={row.uid}
									cartItem={row}
									clickRow={handleClick}
									addItemToCart={addItemToCart}
									removeItemFromCart={removeItemFromCart}
								/>
							);
						})}
					</TableWrapper>

					{currentRecommended.length > 0 ? (
						<Box className="marketingHint">
							<Box
								className="verticalFaderStatic"
								sx={{
									background: `linear-gradient(0deg, ${alpha(bg, 1)} 0%, ${alpha(bg, 0)} 100%)`
								}}
							/>
							<Typography variant="h5">{t("checkout.orderReview.marketingHint")}</Typography>

							<ThemeProvider theme={theme.customTheme}>
								<Grid container spacing={0}>
									<Grid size={{ xs: 0.5 }}></Grid>
									<Grid size={{ xs: 11 }}>
										<Grid container rowSpacing="1rem" columnSpacing="4.7rem">
											{currentRecommended.map(
												(item: ItemSelectable, index: number): ReactNode =>
													index < 3 && (
														<ItemCard
															key={item.itemInfo.uid}
															itemSelectable={item}
															menuColumn={3}
															itemCount={0}
															onNewCartItem={onNewCartItemFromSuggested}
															decreaseItemCart={doNothing}
														/>
													)
											)}
										</Grid>
									</Grid>
								</Grid>
							</ThemeProvider>
						</Box>
					) : null}

					<div className="verticalFiller" />
					{currentRecommended.length === 0 ? (
						<Box
							className="verticalFader"
							sx={{
								background: `linear-gradient(0deg, ${alpha(bg, 1)} 0%, ${alpha(bg, 0)} 100%)`
							}}
						/>
					) : null}
				</Box>
			</Box>

			<OrderReviewBottomBar amount={cart.amount} itemsCount={cart.itemsCount} closeModal={closeModal} confirmOrder={confirmOrder} />
		</DialogFull>
	);
};

export default memo(OrderReviewDialog);
