import { cloneDeep } from "lodash";
import { ActionType } from "../constants";
import { defaultRestaurantInfo, defaultUpSellingItem } from "../constants/defaults";
import { RestaurantInfo, SettingsConfig, UpSellingItem } from "../types";
import { isObject } from "./functions";

export class RestaurantInfoVersionMapper {
	restaurantInfo: RestaurantInfo;

	constructor(restaurantInfo: RestaurantInfo) {
		this.restaurantInfo = restaurantInfo;
	}

	map(): RestaurantInfo {
		return {
			...this.mapProperties(defaultRestaurantInfo, this.restaurantInfo),
			config:
				this.restaurantInfo.config == null
					? defaultRestaurantInfo.config
					: {
							...this.mapProperties(defaultRestaurantInfo.config, this.restaurantInfo.config),
							settings:
								this.restaurantInfo.config.settings == null
									? defaultRestaurantInfo.config.settings
									: this.restaurantInfo.config.settings.map((settingsConfig: SettingsConfig) => {
											const converted = this.convertCardActions(settingsConfig);
											const mapped = this.mapProperties(defaultRestaurantInfo.config.settings[0], converted);
											return { ...this.adaptKioskIds(mapped) };
										}),
							themes:
								this.restaurantInfo.config.themes == null
									? defaultRestaurantInfo.config.themes
									: {
											custom:
												this.restaurantInfo.config.themes.custom == null
													? defaultRestaurantInfo.config.themes.custom
													: {
															...this.mapProperties(
																defaultRestaurantInfo.config.themes.custom,
																this.restaurantInfo.config.themes.custom
															),
															color: {
																...this.mapProperties(
																	defaultRestaurantInfo.config.themes.custom.color,
																	this.restaurantInfo.config.themes.custom.color
																)
															},
															font: {
																...this.mapProperties(
																	defaultRestaurantInfo.config.themes.custom.font,
																	this.restaurantInfo.config.themes.custom.font
																)
															},
															imageSet: {} // z - from version 1.4.5
														},
											system:
												this.restaurantInfo.config.themes.system == null
													? defaultRestaurantInfo.config.themes.system
													: {
															...this.mapProperties(
																defaultRestaurantInfo.config.themes.system,
																this.restaurantInfo.config.themes.system
															),
															color: {
																...this.mapProperties(
																	defaultRestaurantInfo.config.themes.system.color,
																	this.restaurantInfo.config.themes.system.color
																)
															},
															font: {
																...this.mapProperties(
																	defaultRestaurantInfo.config.themes.system.font,
																	this.restaurantInfo.config.themes.system.font
																)
															},
															imageSet: {} // no longer used - leave for retro-compatibility - from version 1.4.5
														}
										},
							upSellingList:
								this.restaurantInfo.config.upSellingList == null
									? defaultRestaurantInfo.config.upSellingList
									: this.restaurantInfo.config.upSellingList.map((upSellingItem: UpSellingItem) => {
											return {
												...this.mapProperties(defaultUpSellingItem, upSellingItem)
											};
										})
						},
			media:
				this.restaurantInfo.media == null
					? defaultRestaurantInfo.media
					: {
							...this.mapProperties(defaultRestaurantInfo.media, this.restaurantInfo.media)
						}
		};
	}

	// Take an object and map (non object) properties using the current version default object as a base
	mapProperties(defaultObject: any, object: any): any {
		// use the defaultObject as base
		const baseObject = cloneDeep(defaultObject);

		try {
			// loop over the object
			Object.entries(baseObject).forEach(([key, baseValue]: [string, any]) => {
				// delete the sub-objects... they must be handled separately
				if (isObject(baseValue)) {
					delete baseObject[key];
				} else {
					// apply the value overriding the default property value
					baseObject[key] = object != null && Object.prototype.hasOwnProperty.call(object, key) ? object[key] : baseValue;
				}
			});
			return baseObject;
		} catch (error) {
			return baseObject;
		}
	}

	// Starting with version 1.4.3 the KioskIds range is [101 - 199]. Previously it was [1 - 99].
	// Older Settings objects must be adapted accordingly (+100)
	adaptKioskIds(settingsConfig: SettingsConfig): SettingsConfig {
		if (settingsConfig.kioskId === 0 || settingsConfig.kioskId > 100) return settingsConfig;

		const newSettingConfig = cloneDeep(settingsConfig);
		newSettingConfig.kioskId = settingsConfig.kioskId + 100;

		return newSettingConfig;
	}

	// Starting with version 2.0.0 the three cardActions flags have been replaced by buttonAction and boxAction
	convertCardActions(settingsConfig: SettingsConfig): SettingsConfig {
		if (settingsConfig.cardButtonsBehaviourInverted == null && settingsConfig.cardHideLinkToDetails == null && settingsConfig.cardHideOneClickAdd == null) {
			return settingsConfig;
		}

		const newSettingConfig = cloneDeep(settingsConfig);

		const addAction = settingsConfig.cardHideOneClickAdd ? ActionType.none : ActionType.add;
		const detailsAction = settingsConfig.cardHideLinkToDetails ? ActionType.none : ActionType.details;

		newSettingConfig.buttonAction = settingsConfig.cardButtonsBehaviourInverted ? detailsAction : addAction;
		newSettingConfig.boxAction = settingsConfig.cardButtonsBehaviourInverted ? addAction : detailsAction;
		newSettingConfig.cardButtonsBehaviourInverted = undefined;
		newSettingConfig.cardHideLinkToDetails = undefined;
		newSettingConfig.cardHideOneClickAdd = undefined;

		return newSettingConfig;
	}
}
