import { SoftwareVersion } from "../types";

/**
 * Convert a version string to a SoftwareVersion object.
 *
 * @param version The version string to parse.
 * @returns The SoftwareVersion object.
 * @example
 */
export function parseVersion(version: string): SoftwareVersion {
	// Split version string into major, minor, patchEnvTagString and tagVersion, splitting all dots.
	const [major, minor, patchEnvTagString, tagVersion] = version.split(/\./);

	// Extract patch and envTagString splitting on first dash.
	const [patch, envTagString] = patchEnvTagString.split(/-(.+)/);

	// Extract environment form envTagString. If envTagString is "dev" or "stg", it's the environment.
	// If envTagString starts with "dev-" or "stg-", split into environment and tag splitting of first dash.
	// otherwise, it's a tag.
	let env: string | undefined;
	let tag: string | undefined;
	if (envTagString) {
		if (envTagString === "dev" || envTagString === "stg") {
			env = envTagString;
		} else {
			if (envTagString.startsWith("dev-") || envTagString.startsWith("stg-")) {
				const [envString, tagString] = envTagString.split(/-(.+)/);
				env = envString;
				tag = tagString;
			} else {
				tag = envTagString;
			}
		}
	}

	return {
		name: version,
		major: parseInt(major),
		minor: parseInt(minor),
		patch: parseInt(patch),
		env: env as "dev" | "stg" | undefined,
		tag: tag ?? undefined,
		tagVersion: tagVersion ? parseInt(tagVersion) : undefined
	};
}

/**
 * Compare two version strings.
 * - Returns a negative number if version1 is lower than version2.
 * - Returns a positive number if version1 is higher than version2.
 * - Returns 0 if version1 is equal to version2.
 */
export function compareVersions(version1: string, version2: string): number {
	const v1 = parseVersion(version1);
	const v2 = parseVersion(version2);

	if (v1.major !== v2.major) {
		return v1.major - v2.major;
	}

	if (v1.minor !== v2.minor) {
		return v1.minor - v2.minor;
	}

	if (v1.patch !== v2.patch) {
		return v1.patch - v2.patch;
	}

	// Compare environments if they exist. Please note a version without an environment is considered higher than a version with an environment.
	if (v1.env && !v2.env) {
		return -1;
	}

	if (!v1.env && v2.env) {
		return 1;
	}

	if (v1.env && v2.env) {
		if (v1.env !== v2.env) {
			return v1.env.localeCompare(v2.env);
		}
	}

	// Compare tags if they exist. Please note a version without a tag is considered higher than a version with a tag.
	if (v1.tag && !v2.tag) {
		return -1;
	}

	if (!v1.tag && v2.tag) {
		return 1;
	}

	if (v1.tag && v2.tag) {
		if (v1.tag !== v2.tag) {
			return v1.tag.localeCompare(v2.tag);
		}

		if (v1.tagVersion && v2.tagVersion) {
			return v1.tagVersion - v2.tagVersion;
		}
	}

	return 0;
}

/**
 * Get Major version from a version string.
 * @param version The version string.
 */
export function getMajorVersion(version: string): number | null {
	if (!version) {
		return null;
	}
	return parseVersion(version).major;
}
