import axios, { AxiosError, AxiosResponse, HttpStatusCode } from "axios";
import { getKioskAuth } from "../StorageWrapper";

type Method = "head" | "options" | "put" | "post" | "patch" | "delete" | "get";

/**
 * Represents a generic payload object where the keys are strings and the values can be of any type.
 */
export interface Payload {
	[key: string]: any;
}

/**
 * Interface representing the structure of an Axios request.
 *
 * @param {Method} method - The HTTP method to use for the request.
 * @param {string} url - The URL to which the request is made.
 * @param {string | FormData} body - The data to be sent as the request body.
 * @param {string} headers - The headers to be sent with the request.
 * @param {boolean} [cashsystem] - A flag to indicate if the request is to be made to the cash system.
 * @param {number} [retry] - The number of times to retry the request in case of a 400 Bad Request response.
 */
export interface axiosRequestInterface {
	method: Method;
	url: string;
	body: string | FormData;
	headers: string;
	cashsystem?: boolean;
	retry?: number;
}

/**
 * Interface representing the structure of an Axios response.
 *
 * @param {HttpStatusCode} status - The status code of the response.
 * @param {any} data - The data returned by the response.
 */
export interface callAxiosResponseInterface {
	status: HttpStatusCode;
	data: any;
}

/**
 * A function that waits for a specified number of milliseconds.
 *
 * @param {number} ms - The number of milliseconds to wait.
 * @returns {Promise<void>} A promise that resolves after the specified number of milliseconds.
 */
const wait = (ms: number) => {
	return new Promise((resolve) => setTimeout(resolve, ms));
};

/**
 * Makes an HTTP request using Axios with optional retry logic.
 *
 * @param {axiosRequestInterface} request - The request configuration object.
 * @param {number} [retryNumber=0] - The number of times to retry the request in case of a 400 Bad Request response.
 * @param {number} [delay=500] - The delay in milliseconds between retries.
 * @param {number} [timeout=20000] - The timeout in milliseconds for the request.
 * @returns {Promise<callAxiosResponseInterface>} A promise that resolves with the response or rejects with an error.
 */
export const callAxios = (
	request: axiosRequestInterface,
	retryNumber: number = 0,
	delay: number = 500,
	timeout: number = 20000
): Promise<callAxiosResponseInterface> => {
	return new Promise((resolve, reject) => {
		const output: callAxiosResponseInterface = { status: HttpStatusCode.InternalServerError, data: null };

		const configs = request.cashsystem
			? { headers: request.headers, timeout: timeout }
			: {
					headers: {
						"Content-Type": "application/json",
						kioskauth: getKioskAuth(),
						...JSON.parse(request.headers)
					},
					timeout: timeout
				};
		const body = request.cashsystem ? request.body : JSON.parse(request.body as string);

		const axiosRequest = request.method === "get" ? [configs] : [body, configs];
		axios[request.method](request.url, ...axiosRequest)
			.then((resp: AxiosResponse) => {
				output.status = resp.status;
				output.data = resp.data;
				resolve(output);
			})
			.catch((err: AxiosError) => {
				if (err.response) {
					if (retryNumber > 0 && err.response?.status === HttpStatusCode.BadRequest) {
						return wait(delay).then(() => {
							callAxios(request, retryNumber--, delay);
						});
					}
					// The request was made and the server responded with a status code that falls out of the range of 2xx
					output.status = err.response?.status;
					output.data = err.response?.data ?? { error_code: err.code };
				} else {
					// if (err.request) The request was made but no response was received
					// otherwise Something happened in setting up the request that triggered an Error
					output.status = HttpStatusCode.ServiceUnavailable;
					output.data = { error_code: err.code };
				}
				reject(output);
			});
	});
};
