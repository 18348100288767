import { useMemo } from "react";
import { Service4DeliveryEndpoints } from "..";
import { buildTheme } from "../../../components/Theme/ThemeConfig";
import { useTheme } from "../../../components/Theme/ThemeWrapper";
import { LogChannel, WebviewApp } from "../../../constants";
import { defaultRestaurantInfo } from "../../../constants/defaults";
import { useCdnAssets } from "../../../hooks/useCdnAssets";
import { useLogger } from "../../../hooks/useLogger";
import { AppConfig, getCloudConfigResponse, RestaurantInfo } from "../../../types";
import { callAxios, callAxiosResponseInterface, Payload } from "../../../utils/httpClient/AxiosWrapper";
import { calculateKioskIdSetting } from "../../../utils/kioskProfile";
import { RestaurantInfoVersionMapper } from "../../../utils/RestaurantInfoVersionMapper";
import { getStorageObject, setStorageObject } from "../../../utils/StorageWrapper";
import { useMenu } from "../Menu/useMenu";

export const useRestaurantInfo = () => {
	const { loadMediaAssets } = useCdnAssets();
	const { log, warn } = useLogger();
	const { settings, bridge, container, setContextTheme, setContextSetting, setContextRestaurantInfo, setContextHideSnoozed, setContextStripeLocationId } =
		useTheme();
	const { downloadMenu } = useMenu();

	const isOnline: boolean = useMemo(() => {
		if (container === WebviewApp.reactNative && bridge.bridgeActive) {
			return bridge.data.isInternetReachable;
		}
		return true;
	}, [bridge.bridgeActive, bridge.data.isInternetReachable, container]);

	const getCloudConfigAndMenu = async () => {
		const restaurantId = localStorage.getItem("username") ?? "";
		const localStripeLocationId = localStorage.getItem("localStripeLocationId") ?? "";
		const storedRestaurantInfo: object | null = getStorageObject("restaurant_info");
		const fallbackRestaurantInfo = storedRestaurantInfo ? (storedRestaurantInfo as RestaurantInfo) : defaultRestaurantInfo;

		const restaurantInfo: RestaurantInfo = isOnline ? await getCloudConfig() : fallbackRestaurantInfo;

		if (localStripeLocationId !== restaurantInfo.default_stripe_location) {
			localStorage.setItem("localStripeLocationId", restaurantInfo.default_stripe_location ?? "");
			if (setContextStripeLocationId) setContextStripeLocationId(restaurantInfo.default_stripe_location);
		}

		setStorageObject("restaurant_info", restaurantInfo);
		setStorageObject("localConfig", restaurantInfo.config ?? defaultRestaurantInfo.config);
		localStorage.setItem("localHideSnoozed", String(restaurantInfo.hide_snoozed ?? defaultRestaurantInfo.hide_snoozed));
		if (setContextTheme && setContextSetting && setContextRestaurantInfo && setContextHideSnoozed) {
			setContextRestaurantInfo(restaurantInfo);
			setContextTheme(buildTheme(restaurantInfo.config));
			setContextSetting(calculateKioskIdSetting(restaurantInfo.config.settings, settings.kioskId));
			setContextHideSnoozed(restaurantInfo.hide_snoozed ?? defaultRestaurantInfo.hide_snoozed);
		}

		if (isOnline) {
			loadMediaAssets(restaurantInfo.media, restaurantInfo.media_baseurl, restaurantId).catch(() => {
				// warn(["Loading media assets: ", error], LogChannel.cloudSync);
			});
		}

		const forceMenuDownload: boolean = restaurantInfo.config.versionNumber !== defaultRestaurantInfo.config.versionNumber;
		if (forceMenuDownload) {
			restaurantInfo.config.versionNumber = defaultRestaurantInfo.config.versionNumber;
			if (isOnline) {
				await setCloudConfig(restaurantInfo.config);
			}
		}

		if (isOnline) {
			await downloadMenu(forceMenuDownload);
		}
	};

	const getCloudConfig = async (): Promise<RestaurantInfo> => {
		return new Promise((resolve) => {
			callAxios({
				method: "post",
				url: Service4DeliveryEndpoints.configGet,
				body: JSON.stringify({}),
				headers: JSON.stringify({ accept: "*/*" })
			})
				.then((response: callAxiosResponseInterface) => {
					log("RestaurantInfo downloaded", LogChannel.cloudSync);
					const goodResponse = response as getCloudConfigResponse;

					// adapt the new restaurantInfo to the current version of the software
					resolve(new RestaurantInfoVersionMapper(goodResponse.data).map());
				})
				.catch((err) => {
					warn(["RestaurantInfo - get error: ", err], LogChannel.cloudSync);

					// try to load restaurantInfo from storage;
					const storedRestaurantInfo: object | null = getStorageObject("restaurant_info");
					resolve(storedRestaurantInfo ? (storedRestaurantInfo as RestaurantInfo) : defaultRestaurantInfo);
				});
		});
	};

	const setCloudConfig = async (currentConfig: AppConfig, hideSnoozed?: number): Promise<boolean> => {
		const payload: Payload = {
			config: JSON.stringify(currentConfig)
		};
		if (hideSnoozed !== undefined) payload.hide_snoozed = hideSnoozed;

		return new Promise((resolve) => {
			callAxios({
				method: "post",
				url: Service4DeliveryEndpoints.configSet,
				body: JSON.stringify(payload),
				headers: JSON.stringify({ accept: "*/*" })
			})
				.then((response: callAxiosResponseInterface) => {
					log("RestaurantInfo - Config uploaded", LogChannel.cloudSync);
					resolve(true);
				})
				.catch((err) => {
					warn(["RestaurantInfo - Set config error: ", err], LogChannel.cloudSync);
					resolve(false);
				});
		});
	};

	return { getCloudConfigAndMenu, setCloudConfig };
};
