import { cloneDeep } from "lodash";
import { ItemInfoTypology, ItemLinkedListMode, TicketFormatType } from "../constants";
import { upSellingItemNotOrdered } from "../constants/defaults";
import {
	Category,
	CategoryChild,
	CategoryList,
	CheckoutMenu,
	ItemInfo,
	ItemInfoTranslations,
	ItemLinkedList,
	ItemSelectable,
	KioskMenu,
	MenuTicketFormat,
	UpSellingItem,
	Vat
} from "../types";
import { valueToCents } from "./Numbers";
import { asciiSafeConverter } from "./Strings";

interface FieldChecker {
	hasCategoryListLink: boolean;
	hasCategoryOptListLink: boolean;
	hasItemLinkedList: boolean;
	hasMandatoryFields: boolean;
	hasFields: boolean;
	isSetMenu: boolean;
	hasDepthLevel3: boolean;
}

export class menuAdapter {
	kioskMenu: KioskMenu;
	categoryList: CategoryList[];
	imageList: string[];
	vatList: Vat[];
	upSellingList: UpSellingItem[];
	tickets: MenuTicketFormat[];
	shortTextMaxLength: number = 80;

	constructor(kioskMenu: KioskMenu) {
		this.kioskMenu = kioskMenu;
		this.categoryList = this.removeCategoryListDuplicates(this.kioskMenu.categoryList);
		this.imageList = [];
		this.vatList = [];
		this.upSellingList = [];
		this.tickets = [];
	}

	/**
	 * Loops through out the Menu structure and adapt it to the App needs:
	 * - TEMP - variation list of "subtraction" type has max Selling Items forced to 1
	 * - remove category list clones
	 * - create a list of products images path (ready to be pre-loaded)
	 * - create a list of Vats (to be mapped afterward in admin configuration)
	 * - create a list of Plu Items for upSelling (to be cross-checked with previously uploaded ordered list in configuration, if any)
	 * - mark categories containing only snoozed products as "snoozed"
	 * - convert prices to current format
	 * - trim shortText item description too shortTextMaxLength characters
	 * - add additional infos to single products (hasFields, hasMandatoryFields, isSetMenu, hasThirdLevel etc...)
	 * @returns KioskMenu
	 */
	adapt(): KioskMenu {
		this.imageList = [];
		this.vatList = [];
		this.upSellingList = [];
		this.tickets = [];

		const copiedCategoryList: CategoryList[] = cloneDeep(this.categoryList);
		this.categoryList = copiedCategoryList.map((categoryList: CategoryList) => {
			return {
				...categoryList,
				itemLinkedList: categoryList.itemLinkedList?.map((itemLinkedList: ItemLinkedList) => {
					return {
						...this.temporaryAlterItemLinkedListSubtraction(itemLinkedList),
						itemSelectable: this.recursivelyElaborateItemSelectable(itemLinkedList.itemSelectable, this.categoryList, "", ""),
						itemSelectableAllSnoozed: this.isItemSelectableAllSnoozed(itemLinkedList.itemSelectable),
						tempId: ""
					};
				})
			};
		});

		return {
			...this.kioskMenu,
			checkoutMenus: this.kioskMenu.checkoutMenus.map((checkoutMenu: CheckoutMenu) => {
				return {
					...checkoutMenu,
					categories: checkoutMenu.categories.map((category: Category) => {
						const parentCategoryIcon: string = category.icon;
						return {
							...category,
							children: category.children.map((categoryChild: CategoryChild) => {
								const catIdIndex: number = this.tickets.findIndex((mtf: MenuTicketFormat) => mtf.id === categoryChild.categoryCode);
								if (catIdIndex === -1) {
									this.tickets.push({
										id: categoryChild.categoryCode,
										desc: categoryChild.categoryTitle,
										type: TicketFormatType.categoryChild,
										cat: "",
										plu: []
									});
								}
								return {
									...categoryChild,
									itemSelectable: this.recursivelyElaborateItemSelectable(
										categoryChild.itemSelectable,
										this.categoryList,
										parentCategoryIcon,
										categoryChild.categoryCode,
										true
									),
									itemSelectableAllSnoozed: this.isItemSelectableAllSnoozed(categoryChild.itemSelectable)
								};
							})
						};
					})
				};
			}),
			categoryList: this.categoryList,
			images: this.imageList,
			vatList: this.vatList.sort((a, b) => (a.id < b.id ? -1 : 1)),
			upSellingList: this.upSellingList,
			tickets: this.tickets
		};
	}

	isItemSelectableAllSnoozed(items: ItemSelectable[]): boolean {
		const newItems: ItemSelectable[] = cloneDeep(items);
		let allSnoozed: boolean = true;

		newItems.forEach((itemSelectable: ItemSelectable) => {
			allSnoozed = allSnoozed && itemSelectable.itemInfo.snoozed === "true";
		});

		return allSnoozed;
	}

	recursivelyElaborateItemSelectable(
		items: ItemSelectable[],
		lists: CategoryList[],
		parentCategoryIcon: string,
		cat: string,
		isFirstLevel: boolean = false
	): ItemSelectable[] {
		const newItems: ItemSelectable[] = cloneDeep(items);

		return newItems.map((itemSelectable: ItemSelectable) => {
			const { hasCategoryListLink, hasCategoryOptListLink, hasItemLinkedList, hasMandatoryFields, hasFields, isSetMenu, hasDepthLevel3 }: FieldChecker =
				this.checkLinkedLists(itemSelectable, lists);

			// Populate image list
			if (itemSelectable.itemInfo.imageName) {
				this.imageList.push(itemSelectable.itemInfo.imageName);
			}
			// Populate VAT rates list
			let calculatedVatRef: string = itemSelectable.itemInfo.vatRef ?? "";
			if (itemSelectable.itemInfo.tipology === ItemInfoTypology.plu && itemSelectable.itemInfo.price !== 0) {
				const newVat: Vat | null = this.createVatFromMenu(itemSelectable.itemInfo.vatRef, itemSelectable.itemInfo.vat);
				if (newVat !== null) {
					const itemIndex: number = this.vatList.findIndex((item) => item.id === newVat.id);
					calculatedVatRef = newVat.id ?? "";
					if (itemIndex === -1) this.vatList.push(newVat);
				}
			}

			// Item info
			const trimmedShortText = itemSelectable.itemInfo.shortText.substring(0, this.shortTextMaxLength);
			const newItemInfo: ItemInfo = {
				...itemSelectable.itemInfo,
				shortText: trimmedShortText,
				price: valueToCents(itemSelectable.itemInfo.price),
				vatRef: calculatedVatRef,
				translations: itemSelectable.itemInfo.translations?.map((translation: ItemInfoTranslations) => {
					const newTranslation: ItemInfoTranslations = translation;
					const trimmedTranslationShortText = translation.shortText.substring(0, this.shortTextMaxLength);
					newTranslation.shortText = trimmedTranslationShortText;
					const translationShortTextAsciiSafe = asciiSafeConverter(trimmedTranslationShortText);
					if (trimmedTranslationShortText !== translationShortTextAsciiSafe) newTranslation.shortTextAsciiSafe = translationShortTextAsciiSafe;
					return newTranslation;
				})
			};
			const shortTextAsciiSafe = asciiSafeConverter(trimmedShortText);
			if (trimmedShortText !== shortTextAsciiSafe) newItemInfo.shortTextAsciiSafe = shortTextAsciiSafe;

			const calculatedItemSelectable: ItemSelectable = {
				...itemSelectable,
				itemInfo: newItemInfo,
				parentCategoryIcon: parentCategoryIcon,
				hasItemLinkedList: hasItemLinkedList,
				hasCategoryListLink: hasCategoryListLink,
				hasCategoryOptListLink: hasCategoryOptListLink,
				hasMandatoryFields: hasMandatoryFields,
				hasFields: hasFields,
				isSetMenu: isSetMenu,
				hasDepthLevel3: hasDepthLevel3,
				itemLinkedList: itemSelectable.itemLinkedList?.map((itemLinkedList: ItemLinkedList) => {
					return {
						...this.temporaryAlterItemLinkedListSubtraction(itemLinkedList),
						itemSelectable: this.recursivelyElaborateItemSelectable(itemLinkedList.itemSelectable, lists, "", ""),
						itemSelectableAllSnoozed: this.isItemSelectableAllSnoozed(itemLinkedList.itemSelectable),
						tempId: ""
					};
				})
			};

			//Populate UpSelling list
			if (isFirstLevel && itemSelectable.itemInfo.tipology === ItemInfoTypology.plu) {
				const pluIdIndex: number = this.upSellingList.findIndex((item: UpSellingItem) => item.pluId === itemSelectable.itemInfo.uid);
				if (pluIdIndex === -1) {
					this.upSellingList.push({
						pluId: itemSelectable.itemInfo.uid,
						itemSelectable: calculatedItemSelectable,
						order: upSellingItemNotOrdered,
						excluded: false
					});
				}
			}

			// Tickets
			if (itemSelectable.itemInfo.tipology === ItemInfoTypology.plu && cat) {
				const parentTicket = this.tickets.find((mtf: MenuTicketFormat) => cat === mtf.id);
				if (parentTicket) {
					const pluIdIndex2: number = parentTicket.plu.findIndex((mtf: MenuTicketFormat) => mtf.id === itemSelectable.itemInfo.uid);
					if (pluIdIndex2 === -1) {
						parentTicket.plu.push({
							id: itemSelectable.itemInfo.uid,
							desc: itemSelectable.itemInfo.shortText,
							type: TicketFormatType.plu,
							cat: cat,
							plu: []
						});
					}
				}
			}

			return calculatedItemSelectable;
		});
	}

	checkLinkedLists(itemSelectable: ItemSelectable, lists: CategoryList[]): FieldChecker {
		// Linked list without category
		let itemLinkedLists: ItemLinkedList[] = itemSelectable.itemLinkedList;

		// Category List
		const categoryListLink: CategoryList[] = lists.filter((category: CategoryList) => itemSelectable.categoryListLink.includes(category.categoryListId));
		categoryListLink?.forEach((category: CategoryList) => {
			itemLinkedLists = [...itemLinkedLists, ...category.itemLinkedList];
		});

		// Optional Category List
		const categoryOptListLink: CategoryList[] = lists.filter((category: CategoryList) =>
			itemSelectable.categoryOptListLink.includes(category.categoryListId)
		);
		categoryOptListLink?.forEach((category: CategoryList) => {
			itemLinkedLists = [...itemLinkedLists, ...category.itemLinkedList];
		});

		// Search for linked list where an action is necessary
		const mandatory: ItemLinkedList | undefined = itemLinkedLists?.find((itemLinkedList: ItemLinkedList) => itemLinkedList.minSelItems > 0);

		// Is it a Set Menu? (i.e. this PLU has at least one linked list made of Items of type PLU)
		const isSetMenu: boolean = itemLinkedLists?.some((itemLinkedLists: ItemLinkedList) =>
			itemLinkedLists.itemSelectable.some((itemSelectable: ItemSelectable) => itemSelectable.itemInfo.tipology === ItemInfoTypology.plu)
		);

		// Menu Depth of Level 3? (e.g. 1. Set Menu -> 2. Select between PLUs options -> 3. Mandatory cond_add)
		let hasDepthLevel3: boolean = false;
		if (isSetMenu) {
			hasDepthLevel3 = itemLinkedLists?.some((itemLinkedLists: ItemLinkedList) =>
				itemLinkedLists.itemSelectable.some((itemSelectable: ItemSelectable) => itemSelectable.hasMandatoryFields === true)
			);
		}

		return {
			hasCategoryListLink: categoryListLink.length > 0,
			hasCategoryOptListLink: categoryOptListLink.length > 0,
			hasItemLinkedList: itemSelectable.itemLinkedList.length > 0,
			hasMandatoryFields: mandatory !== undefined,
			hasFields: itemLinkedLists.length > 0,
			isSetMenu: isSetMenu,
			hasDepthLevel3: hasDepthLevel3
		};
	}

	removeCategoryListDuplicates(lists: CategoryList[]): CategoryList[] {
		const newLists: CategoryList[] = cloneDeep(lists);

		// remove duplicate Category List
		return newLists.filter((value, index, self) => index === self.findIndex((t) => t.categoryListId === value.categoryListId));
	}

	/**
	 *	TEMPORARY HACK - Currently Cash system does not handle subtraction list item variations cardinality.
	 *	For this reason subtraction list items must be handled as single quantity variation (No matter maxSelItems of the list says).
	 *	e.g. For a Sandwich you can subtract "lettuce". You can't remove 2 portions of Lettuce.
	 *
	 *  Hence, here, the maxSelItems is capped to the number of variations for this list.
	 *  Not enough! To make each variation limited to just 1 quantity, a JSX component check is needed.
	 */
	temporaryAlterItemLinkedListSubtraction(item: ItemLinkedList): ItemLinkedList {
		return {
			...item,
			maxSelItems: item.listMode === ItemLinkedListMode.sub ? Math.min(item.itemSelectable.length, item.maxSelItems) : item.maxSelItems
		};
	}

	createVatFromMenu(vatRef: string, vat: string): Vat | null {
		if (vat !== null && vatRef !== null) {
			try {
				const parsed: Vat = JSON.parse(vatRef);

				const refCode = parsed.refCode ?? "";
				const rate = vat.padStart(5, "0");
				// N2.1 and N2.2 are the same exemption type for the cash system (N2)
				const exemption = parsed.exemption
					? parsed.exemption.includes(".")
						? parsed.exemption.substring(0, parsed.exemption.indexOf("."))
						: parsed.exemption
					: "";
				const goodType = parsed.goodType;
				const breakdown = parsed.breakdown;
				const id = [refCode, rate, exemption, goodType, breakdown].join("_");

				return {
					id: id,
					refCode: refCode,
					rate: rate,
					exemption: exemption,
					goodType: goodType,
					breakdown: breakdown,
					cashDepartment: ""
				};
			} catch (error) {
				return null;
			}
		}
		return null;
	}
}
