import { AddShoppingCart, ArrowBack, Cloud, ColorLens, Login, Preview, Settings } from "@mui/icons-material";
import { Box, Button, CircularProgress, Container, Stack, Tab, Tabs, ThemeProvider, Typography } from "@mui/material";
import { cloneDeep } from "lodash";
import { ReactNode, SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useIdleTimer } from "react-idle-timer";
import { useNavigate, useParams } from "react-router";
import { AdminRole, LogChannel, RoutePath, WebviewApp } from "../../constants";
import { defaultConfig, defaultRestaurantInfo } from "../../constants/defaults";
import { useGetRestaurantFileContent } from "../../hooks/useCdnAssets";
import { useLogger } from "../../hooks/useLogger";
import useStaticImage from "../../hooks/useStaticImage";
import { GetMenuFromStorage, useRestaurantInfo } from "../../services/4Delivery";
import { AppConfig, KioskMenu, LayoutConfig, SettingsConfig, UpSellingItem } from "../../types";
import { addKioskProfile, calculateKioskIdSetting } from "../../utils/kioskProfile";
import { getStorageObject, setStorageObject } from "../../utils/StorageWrapper";
import { useMessage } from "../MessageHandler/MessageService";
import { buildTheme } from "../Theme/ThemeConfig";
import { useTheme } from "../Theme/ThemeWrapper";
import "./Admin.scss";
import AdminAccess from "./AdminAccess";
import AdminCloud from "./AdminCloud";
import AdminPreview from "./AdminPreview";
import AdminTheme from "./AdminTheme";
import AdminUpSelling from "./AdminUpSelling";
import AdminSettings from "./settings/AdminSettings";

interface TabPanelProps {
	children?: ReactNode;
	index: number;
	tabValue: number;
	tabKey: string;
	description: string;
}

const Admin = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const message = useMessage();
	const { log } = useLogger();
	const { theme, settings, hideSnoozed, restaurantInfo, bridge, container, setContextTheme, setContextSetting } = useTheme();
	const { getCloudConfigAndMenu, setCloudConfig } = useRestaurantInfo();
	const { loading: logoLoading, error: logoError, image: logoImage } = useStaticImage("system/logoLabware.svg");
	const { loading: iqSelfLoading, error: iqSelfError, image: iqSelfImage } = useStaticImage("system/logoIQSelf.svg");
	const { image: iqSelfFaviconImage } = useStaticImage("system/faviconIQSelf.svg");
	const { image: logoAnyDeskImage } = useStaticImage("system/logoAnyDesk.svg");
	const { content: disclaimer, isFallback: disclaimerNotFound } = useGetRestaurantFileContent("allergen");
	const versionNumber: string = defaultRestaurantInfo.config.versionNumber;
	const kioskMenu: KioskMenu = GetMenuFromStorage();
	const { role } = useParams();
	const isAdmin: boolean = (Number(role) as AdminRole) === AdminRole.administrator;
	const currentAppVersion = useMemo(() => {
		if (container === WebviewApp.reactNative && bridge.bridgeActive) {
			return bridge.data.appVersion ?? "";
		}
		return "";
	}, [bridge.bridgeActive, bridge.data.appVersion, container]);

	const [currentConfig, setCurrentConfig] = useState<AppConfig>(theme.config);
	const [currentPin, setCurrentPin] = useState<string>(theme.config.pin);
	const [currentPinOperator, setCurrentPinOperator] = useState<string>(theme.config.pinOperator);
	const [currentUpSellingList, setCurrentUpSellingList] = useState<UpSellingItem[]>(theme.config.upSellingList);
	const [currentSettings, setCurrentSettings] = useState<SettingsConfig>(settings);
	const [currentCustomTheme, setCurrentCustomTheme] = useState<LayoutConfig>(theme.config.themes.custom);
	const [currentHideSnoozed, setCurrentHideSnoozed] = useState<number>(hideSnoozed);
	const [configDownloadLoading, setConfigDownloadLoading] = useState<boolean>(true);
	const [configUploadLoading, setConfigUploadLoading] = useState<boolean>(false);
	const [satispayDisabled, setSatispayDisabled] = useState<boolean>(false);

	const propagateCurrentConfig = useCallback(
		(newConfig: AppConfig, newKioskId: number): void => {
			if (setContextTheme && setContextSetting) {
				setStorageObject("localConfig", newConfig);
				setContextSetting(calculateKioskIdSetting(newConfig.settings, newKioskId));
				setContextTheme(buildTheme(newConfig));
			}
		},
		[setContextTheme, setContextSetting]
	);

	useIdleTimer({
		name: "5minutes-inactive-go-back-home",
		onIdle: () => {
			navigate(RoutePath.homePage);
		},
		timeout: 1000 * 60 * 5
	});

	// Do not access Admin page without first logging in (with retrieved data)
	useEffect(() => {
		const restaurantId = getStorageObject("restaurant_info") ?? "";
		if (!restaurantId) {
			const localConfig = getStorageObject("localConfig") ?? "";
			if (localConfig) localStorage.removeItem("localConfig");
			navigate(RoutePath.homePage);
		}
	});

	// Download config from cloud... before starting editing Config.
	useEffect(() => {
		const initialize = async () => {
			await getCloudConfigAndMenu();
			setConfigDownloadLoading(false);
			setCurrentSettings(settings);
		};

		initialize();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []); // I don't want to include settings in dependency list

	useEffect(() => {
		localStorage.setItem("localHideSnoozed", String(currentHideSnoozed));
	}, [currentHideSnoozed]);

	useEffect(() => {
		const localConfig: AppConfig = cloneDeep(currentConfig);
		localConfig.pin = currentPin;
		localConfig.pinOperator = currentPinOperator;
		localConfig.upSellingList = currentUpSellingList;
		localConfig.themes.custom = cloneDeep(currentCustomTheme);
		localConfig.themes.system = defaultConfig.systemTheme;
		localConfig.settings[getIndex(localConfig.settings, currentSettings.kioskId)] = cloneDeep(currentSettings);
		setCurrentConfig(localConfig);
		propagateCurrentConfig(localConfig, currentSettings.kioskId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentSettings, currentCustomTheme, currentPin, currentPinOperator, currentUpSellingList, propagateCurrentConfig]); // I don't want to include currentConfig in dependency list

	const getIndex = (settings: SettingsConfig[], kioskId: number): number => {
		if (!kioskId) return 0;
		return settings.findIndex((config: SettingsConfig) => config.kioskId === kioskId);
	};

	const handleCreateKioskId = (newKioskId: number) => {
		const newConfig: AppConfig = cloneDeep(currentConfig);
		newConfig.settings = addKioskProfile(newConfig.settings, newKioskId);
		setCurrentConfig(newConfig);
		setCurrentSettings(newConfig.settings[getIndex(newConfig.settings, newKioskId)]);
	};

	const handleChooseKioskId = (newKioskId: number) => {
		// Find the related setting
		const relatedSetting: SettingsConfig | undefined = currentConfig.settings.find((config: SettingsConfig) => config.kioskId === newKioskId);
		if (relatedSetting) {
			setCurrentSettings(relatedSetting);
		}
	};

	const handleExitAndUploadConfig = async () => exitAndUploadConfig();

	const handleOpenWebPage = async (url: string) => exitAndUploadConfig(url);

	/**
	 * Exit and upload the config to the cloud.
	 * If the user is an admin, ask if they want to save the config before exiting.
	 * If a custom URL is provided, open the URL in a new tab, otherwise navigate to the home page.
	 *
	 * @param url - The URL to open in a new tab.
	 */
	const exitAndUploadConfig = async (url?: string) => {
		if (!isAdmin) {
			navigate(RoutePath.homePage);
		} else {
			message({
				title: t("common.crud.save", { item: t("system.admin.cloud.config") }),
				description: t("system.admin.cloud.saveConfigDesc"),
				okLabel: t("common.yes"),
				okCallback: async () => {
					await uploadConfigToCloud();
					if (url) {
						openExternalWebPage(url);
					} else {
						navigate(RoutePath.homePage);
					}
				},
				thirdActionLabel: t("common.no"),
				thirdActionCallback: () => {
					if (url) {
						openExternalWebPage(url);
					} else {
						navigate(RoutePath.homePage);
					}
				},
				cancelLabel: t("common.cancel"),
				cancelCallback: () => {}
			});
		}
	};

	const uploadConfigToCloud = async () => {
		setConfigUploadLoading(true);
		const snoozedParam: number | undefined = currentHideSnoozed === restaurantInfo.hide_snoozed ? undefined : currentHideSnoozed;
		await setCloudConfig(currentConfig, snoozedParam)
			.then((result: boolean) => {
				if (!result) {
					log("Can't upload the config", LogChannel.cloudSync);
				}
			})
			.finally(() => {
				setConfigUploadLoading(false);
			});
	};

	const openExternalWebPage = (url: string) => {
		if (container === WebviewApp.reactNative && bridge.bridgeActive) {
			bridge.changeWebviewUrl(url, true);
		} else {
			window.open(url, "_blank");
		}
	};

	const [tabValue, setTabValue] = useState<number>(0);

	const handleTabChange = (event: SyntheticEvent, newTabValue: number) => {
		setTabValue(newTabValue);
	};

	const TabPanel = (props: TabPanelProps) => {
		const { children, tabValue, index, tabKey, description, ...other } = props;

		return (
			<Box
				role="tabPanel"
				className={`vertical-tabPanel vertical-tabPanel-${tabKey}`}
				hidden={tabValue !== index}
				id={`vertical-tabPanel-${index}`}
				aria-labelledby={`vertical-tab-${index}`}
				sx={{ p: "1.5rem" }}
				{...other}
			>
				<Box className="panelHeader">
					<Typography variant="h4" color="primary">
						{t(`system.admin.panel.${tabKey}`)}
					</Typography>
					<Typography color={theme.systemTheme.palette.text.primary}>{isAdmin ? description : ""}</Typography>
				</Box>
				{tabValue === index && children}
				{/* <Box sx={{ height: "30vh" }}></Box> */}
			</Box>
		);
	};

	const a11yProps = (index: number) => {
		return {
			id: `vertical-tab-${index}`,
			"aria-controls": `vertical-tabPanel-${index}`
		};
	};

	return (
		<ThemeProvider theme={theme.systemTheme}>
			<Container className="configContainer" sx={{ backgroundColor: theme.systemTheme.palette.background.paper }}>
				<Box className="header" sx={{ borderColor: `${theme.systemTheme.palette.primary.dark} !important` }}>
					<Button
						className={"back"}
						variant="outlined"
						color="info"
						onClick={handleExitAndUploadConfig}
						size="large"
						endIcon={<ArrowBack />}
						disabled={configUploadLoading}
					>
						{t("common.back")}
					</Button>
					<Stack direction="row" alignItems="baseline" justifyContent="space-between">
						<Box className="iqSelf">
							{!iqSelfLoading && !iqSelfError ? <img src={iqSelfImage ?? ""} alt={t("system.admin.title") ?? ""} loading="lazy" /> : null}
						</Box>
						<Typography variant="caption" color={theme.systemTheme.palette.text.secondary}>
							{t("checkout.powered")}
						</Typography>
						<Box className="logo">
							{!logoLoading && !logoError ? (
								<img className="labwareLogo" src={logoImage ?? ""} alt={t("system.admin.labware") ?? ""} loading="lazy" />
							) : null}
						</Box>
					</Stack>
				</Box>
				{configUploadLoading || configDownloadLoading ? (
					<CircularProgress sx={{ position: "absolute", top: "50%", left: "50%" }} />
				) : (
					<Box className="main">
						<Tabs
							className="tabMenu"
							orientation="vertical"
							variant="scrollable"
							value={tabValue}
							onChange={handleTabChange}
							aria-label="vertical-tabs"
							sx={{ borderRight: "0.0625rem", borderColor: "divider" }}
						>
							<Tab {...a11yProps(0)} iconPosition="start" icon={<Login />} label={t("system.admin.panel.access")} />
							<Tab {...a11yProps(1)} iconPosition="start" icon={<Settings />} label={t("system.admin.panel.settings")} />
							<Tab {...a11yProps(2)} iconPosition="start" icon={<Cloud />} label={t("system.admin.panel.cloud")} />
							{isAdmin ? <Tab {...a11yProps(3)} iconPosition="start" icon={<ColorLens />} label={t("system.admin.panel.theme")} /> : null}
							{isAdmin ? <Tab {...a11yProps(4)} iconPosition="start" icon={<Preview />} label={t("system.admin.panel.preview")} /> : null}
							{isAdmin ? (
								<Tab {...a11yProps(5)} iconPosition="start" icon={<AddShoppingCart />} label={t("system.admin.panel.upSelling")} />
							) : null}
						</Tabs>

						<Box className="panels">
							<TabPanel tabValue={tabValue} index={0} tabKey={"access"} description={t("system.admin.access.accessDesc")}>
								<AdminAccess
									isAdmin={isAdmin}
									currentPin={currentPin}
									currentPinOperator={currentPinOperator}
									currentAppVersion={currentAppVersion}
									iqSelfImage={iqSelfImage}
									iqSelfFaviconImage={iqSelfFaviconImage}
									logoAnyDeskImage={logoAnyDeskImage}
									setCurrentPin={setCurrentPin}
									setCurrentPinOperator={setCurrentPinOperator}
								/>
							</TabPanel>
							<TabPanel tabValue={tabValue} index={1} tabKey={"settings"} description={t("system.admin.settings.kioskProfileDescription")}>
								<AdminSettings
									isAdmin={isAdmin}
									onCreateKioskId={handleCreateKioskId}
									onChooseKioskId={handleChooseKioskId}
									currentConfig={currentConfig}
									currentSettings={currentSettings}
									setCurrentSettings={setCurrentSettings}
									currentHideSnoozed={currentHideSnoozed}
									setCurrentHideSnoozed={setCurrentHideSnoozed}
									menuVatList={kioskMenu.vatList}
									menuTickets={kioskMenu.tickets}
									satispayDisabled={satispayDisabled}
									setSatispayDisabled={setSatispayDisabled}
									onOpenWebPage={handleOpenWebPage}
								/>
							</TabPanel>
							<TabPanel tabValue={tabValue} index={2} tabKey={"cloud"} description={t("system.admin.cloud.cloudDesc")}>
								<AdminCloud isAdmin={isAdmin} disclaimer={disclaimer} disclaimerNotFound={disclaimerNotFound} />
							</TabPanel>
							<TabPanel tabValue={tabValue} index={3} tabKey={"theme"} description={t("system.admin.theme.createThemeAction")}>
								<AdminTheme currentCustomTheme={currentCustomTheme} setCurrentCustomTheme={setCurrentCustomTheme} />
							</TabPanel>
							<TabPanel tabValue={tabValue} index={4} tabKey={"preview"} description={t("system.admin.preview.previewDesc")}>
								<AdminPreview kioskMenu={kioskMenu} />
							</TabPanel>
							<TabPanel tabValue={tabValue} index={5} tabKey={"upSelling"} description={t("system.admin.upSelling.upSellingDesc")}>
								<AdminUpSelling
									pluListFromMenu={kioskMenu.upSellingList}
									currentUpSellingList={currentUpSellingList}
									setCurrentUpSellingList={setCurrentUpSellingList}
								/>
							</TabPanel>
						</Box>

						<Box className="version">
							<Typography>{"sw: " + versionNumber}</Typography>
							{currentAppVersion ? <Typography>{"app: " + currentAppVersion}</Typography> : null}
						</Box>
					</Box>
				)}
			</Container>
		</ThemeProvider>
	);
};

export default Admin;
