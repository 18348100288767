// export async function asyncForEach(array: any[], callback: any) {
// 	for (let index = 0; index < array.length; index++) {
// 		await callback(array[index], index, array);
// 	}
// }

/**
 * Check if a value is an object
 *
 * @param value
 * @returns
 */
export function isObject(value: unknown): boolean {
	return typeof value === "object" && !Array.isArray(value) && value !== null ? true : false;
}

/**
 * Delay the execution of the next line of code
 *
 * @param ms - The time to wait in milliseconds
 * @returns
 */
export const delayer = (ms: number) => {
	return new Promise((resolve) => setTimeout(resolve, ms));
};
