import { useEffect, useMemo, useState } from "react";
import { useTheme } from "../components/Theme/ThemeWrapper";
import { Language } from "../constants";
import { defaultConfig } from "../constants/defaults";
import i18n from "../utils/i18n/i18n";

interface useLanguageResponse {
	language: Language;
	setLanguage: (language: Language) => void;
}

/**
 * useLanguage hook
 * @returns useLanguageResponse
 */
export const useLanguage = (): useLanguageResponse => {
	const { settings } = useTheme();
	const availableLanguages = useMemo(() => settings.availableLanguages ?? defaultConfig.settings.availableLanguages, [settings.availableLanguages]);
	const storageLanguage = localStorage.getItem("language");

	/**
	 * Initial language
	 *
	 * It is determined based on:
	 * - the available languages in the current kiosk Profile (SettingConfig)
	 * - the language stored in localStorage.
	 *
	 */
	const initialLanguage: Language = useMemo(() => {
		// if no available languages, return english - should not happen
		if (!availableLanguages || availableLanguages.length === 0) return Language.english;
		// if no language in storage: return english language if available, otherwise first available language
		if (!storageLanguage) {
			if (availableLanguages.includes(Language.english)) return Language.english;
			return availableLanguages[0];
		}
		// if language in storage is available, return it
		if (availableLanguages.includes(storageLanguage as Language)) {
			return storageLanguage as Language;
		} else {
			// if language in storage is not available, return english language if available, otherwise first available language
			if (availableLanguages.includes(Language.english)) return Language.english;
			return availableLanguages[0];
		}
	}, [storageLanguage, availableLanguages]);

	const [currentLanguage, setCurrentLanguage] = useState<Language>(initialLanguage);

	/**
	 * Change language if currentLanguage is different from i18n.language
	 *
	 * When you swap between kiosk profiles, the available languages might change.
	 * This effect will change the language if the current language is not available anymore.
	 * It will also change the language if the language in localStorage is different from the available languages.
	 */
	useEffect(() => {
		if (currentLanguage !== i18n.language) {
			i18n.changeLanguage(currentLanguage);
		}
	}, [currentLanguage]);

	/**
	 * Set language in localStorage and i18n when explicitly called
	 *
	 * @param language
	 */
	const setLanguage = (language: Language) => {
		localStorage.setItem("language", language);
		i18n.changeLanguage(language);
		setCurrentLanguage(language);
	};

	return { language: currentLanguage, setLanguage };
};
