import { defaultConfig } from "../constants/defaults";
import { SettingsConfig } from "../types"; // Ensure this import is correct and the type is defined in the specified file

/**
 * Calculate the kioskId setting based on the settingsConfigList, the kioskId in local storage and eventually the kioskId provided.
 *
 * @param settingsConfigList
 * @param kioskId
 * @returns
 */
export const calculateKioskIdSetting = (settingsConfigList: SettingsConfig[], kioskId?: number): SettingsConfig => {
	const initialKioskId: number = kioskId === undefined ? 0 : kioskId;
	let localKioskId: number = initialKioskId ? initialKioskId : parseInt(localStorage.getItem("kioskId") ?? "0");
	let chosenSettings: SettingsConfig = settingsConfigList[0];

	// Starting with version 1.4.3 the KioskIds range is [101 - 199]. Previously it was [1 - 99].
	// Older Settings objects must be adapted accordingly (+100)
	if (localKioskId > 0 && localKioskId < 101) {
		localKioskId = localKioskId + 100;
	}

	// if in config there are only 2 settings (i.e. the default settings and just one settings configured)
	// and there is no kioskId in local storage
	if (settingsConfigList.length === 2 && localKioskId === 0) {
		chosenSettings = settingsConfigList[1]; //... then choose the only one configured
	} else {
		// otherwise get the configuration related to the localKioskId in storage
		const localConfigKioskId = settingsConfigList.find((sc: SettingsConfig) => sc.kioskId === localKioskId);
		if (localConfigKioskId) {
			chosenSettings = localConfigKioskId;
		}
	}

	// save localKioskId in storage
	localStorage.setItem("kioskId", String(chosenSettings.kioskId));
	return chosenSettings;
};

/**
 * Add a new kiosk profile (SettingsConfig) to the settingsConfigList
 */
export const addKioskProfile = (settingsConfigList: SettingsConfig[], newKioskId: number): SettingsConfig[] => {
	const newSettings: SettingsConfig = { ...defaultConfig.settings, kioskId: newKioskId };

	// check if the new kioskId is already in the list
	const existingKioskId = settingsConfigList.find((sc: SettingsConfig) => sc.kioskId === newKioskId);
	if (existingKioskId) {
		return settingsConfigList;
	}

	return [...settingsConfigList, newSettings];
};

/**
 *  Loop over the array of integers from 101 to 199 (i.e. the whole range of kiosk ids).
 * - return only available ids (Available here means not used as Settings Kiosk Id yet.)
 *
 * @param settingsConfigList
 * @returns
 */
export const getAvailableKioskIds = (settingsConfigList: SettingsConfig[]): number[] =>
	Array.from({ length: 99 }, (_, i) => i + 101).filter((key: number) => {
		return settingsConfigList.every((config: SettingsConfig) => config.kioskId !== key);
	});
