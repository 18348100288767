import { useCallback, useEffect, useState } from "react";
import ReactPlayer from "react-player/lazy";
import { useTheme } from "../components/Theme/ThemeWrapper";
import { legacyDefaultRestaurantInfoMedia } from "../constants/defaults";
import useStaticImage from "../hooks/useStaticImage";
import { RestaurantInfoMedia } from "../types";

export type UseGetRestaurantMediaResponse = { url: string; isFallback: boolean; isVideo: boolean };

/**
 * Custom hook to manage CDN assets
 *
 * 	Expected images features:
 *
 *	BGs
 *	- size: 1080x1920px (ratio 9:16)
 *	- dpi: 120dpi
 *	- extension: png or mp4
 *
 *	Logo
 *	- extension: svg
 *
 * 	Icons
 * 	- ratio: 1:1
 * 	- extension: svg
 *
 *	Product images
 *	- size: 388x280px (ratio ~ 4:3)
 *	- dpi: 120dpi
 *	- extension: png
 *	- subject: a plain photo or a product with background cut out and transparency
 *
 * @returns  {Object}  Object containing functions to get asset paths and load media assets
 */
export const useCdnAssets = () => {
	// The CDN url
	const cdn: string = process.env.REACT_APP_4ORDER_CDN ?? "";

	/**
	 * Get the asset path for a restaurant
	 *
	 * @param   {string}  restaurantId  The restaurant ID
	 * @returns {string}  The asset path
	 */
	const getAssetPath = useCallback(
		(restaurantId: string): string => {
			return cdn + "/xml/" + restaurantId + "/images/kiosk";
		},
		[cdn]
	);

	/**
	 * Get the URL for a system icon
	 */
	const getSystemIconUrl = useCallback(
		(iconCode: string | undefined): string => {
			const pathname: string = iconCode !== null ? "/icons/" + iconCode + ".svg" : "/icons/0.svg";
			return cdn + pathname;
		},
		[cdn]
	);

	/**
	 * Get the URL for a product image
	 */
	const getProductImageUrl = useCallback(
		(imageFile: string, restaurantId: string): string | null => {
			if (!imageFile) return null;
			return cdn + "/xml/" + restaurantId + "/images/" + imageFile;
		},
		[cdn]
	);

	/**
	 * Load media assets for a restaurant
	 */
	const promiseMediaAsset = useCallback(
		(key: string, value: string | null, baseUrl: string | null, restaurantId: string) => {
			const fileName: string = value ?? legacyDefaultRestaurantInfoMedia[key as keyof RestaurantInfoMedia] ?? "";
			const fileUrl: string = (baseUrl ?? getAssetPath(restaurantId)) + "/" + fileName;
			const fileExt: string = fileName === "" ? "" : (fileName.split(".").pop() ?? "");

			return new Promise((resolve, reject) => {
				if (["mp4"].includes(fileExt)) {
					fetch(fileUrl)
						.then((response) => resolve({ fileUrl, status: "ok" }))
						.catch((error) => reject({ fileUrl, status: "error" }));
				} else {
					const image = new Image();
					image.onload = () => resolve({ fileUrl, status: "ok" });
					image.onerror = () => reject({ fileUrl, status: "error" });
					image.src = fileUrl;
				}
			});
		},
		[getAssetPath]
	);

	/**
	 * Pre-load all media assets for a restaurant
	 */
	const loadMediaAssets = useCallback(
		(media: RestaurantInfoMedia, baseUrl: string | null, restaurantId: string) => {
			return Promise.all(
				Object.keys(media)
					.filter((key: string) => key !== ("allergen" as keyof RestaurantInfoMedia))
					.map((key: string) => promiseMediaAsset(key, media[key as keyof RestaurantInfoMedia], baseUrl, restaurantId))
			);
		},
		[promiseMediaAsset]
	);

	/**
	 * Load a product image
	 */
	const promiseProductImage = useCallback(
		(imageName: string, restaurantId: string) => {
			const imgUrl: string = getProductImageUrl(imageName, restaurantId) ?? "";
			return new Promise((resolve, reject) => {
				const image = new Image();
				image.onload = () => resolve({ imgUrl, status: "ok" });
				image.onerror = () => reject({ imgUrl, status: "error" });
				image.src = imgUrl;
			});
		},
		[getProductImageUrl]
	);

	/**
	 * Pre-load all product images
	 */
	const loadProductImages = useCallback(
		(images: string[], restaurantId: string) => {
			return Promise.all(images.map((image) => promiseProductImage(image, restaurantId)));
		},
		[promiseProductImage]
	);

	return {
		getAssetPath,
		getSystemIconUrl,
		getProductImageUrl,
		loadMediaAssets,
		loadProductImages
	};
};

export const useGetRestaurantMedia = (mediaAsset: keyof RestaurantInfoMedia): UseGetRestaurantMediaResponse => {
	const { getAssetPath } = useCdnAssets();
	const { restaurantId, restaurantInfo } = useTheme();
	const fileName: string = restaurantInfo.media[mediaAsset] ?? legacyDefaultRestaurantInfoMedia[mediaAsset] ?? "";
	const fileUrl: string = (restaurantInfo.media_baseurl ?? getAssetPath(restaurantId)) + "/" + fileName;
	const fileExt = fileName.split(".").pop();
	const { loading, image: staticImage, error } = useStaticImage("fallback/" + fileName);

	const [fileSrc, setFileSrc] = useState<string>(fileUrl);
	const [isFallback, setIsFallback] = useState<boolean>(false);
	const [isVideo, setIsVideo] = useState<boolean>(false);

	useEffect(() => {
		if (mediaAsset === "standby" && fileExt === "mp4" && !isFallback) {
			const isVideoPlayable: boolean = ReactPlayer.canPlay(fileSrc);
			if (isVideoPlayable) {
				setIsVideo(true);
			} else {
				setFileSrc(staticImage ?? "");
				setIsFallback(true);
			}
		} else {
			const img = new Image();
			img.src = fileSrc as string;

			img.addEventListener("load", () => {
				if (isFallback) {
					setFileSrc(staticImage ?? "");
				}
			});
			img.addEventListener("error", () => {
				if (!isFallback && !error && !loading && staticImage !== null) {
					setFileSrc(staticImage);
					setIsFallback(true);
				}
			});
		}
	}, [fileSrc, loading, error, isFallback, staticImage, mediaAsset, fileExt]);

	return { url: fileSrc, isFallback, isVideo };
};

export const useGetRestaurantFileContent = (mediaAsset: keyof RestaurantInfoMedia): { content: string; isFallback: boolean } => {
	const { getAssetPath } = useCdnAssets();
	const { restaurantId, restaurantInfo } = useTheme();
	const fileName: string = restaurantInfo.media[mediaAsset] ?? legacyDefaultRestaurantInfoMedia[mediaAsset] ?? "";
	const fileUrl: string = (restaurantInfo.media_baseurl ?? getAssetPath(restaurantId)) + "/" + fileName;

	const [content, setContent] = useState<string>("");
	const [isFallback, setIsFallback] = useState<boolean>(false);

	useEffect(() => {
		// GET request using fetch with error handling
		fetch(fileUrl)
			.then(async (response) => {
				await response.text().then((data) => {
					if (!response.ok) {
						const error = data || response.statusText;
						return Promise.reject(error);
					}
					setContent(data);
				});
			})
			.catch(() => {
				setIsFallback(true);
			});
	}, [fileUrl]);

	return { content, isFallback };
};
