import { cloneDeep } from "lodash";
import { useState } from "react";
import { ItemInfoTypology } from "../constants";
import { defaultCart } from "../constants/defaults";
import { Cart, CartItem, ItemSelectable } from "../types";

interface useCartResponse {
	cart: Cart;
	addItemToCart: (cartItem: CartItem) => void;
	updateItemCart: (cartItem: CartItem) => void;
	removeItemFromCart: (uid: string) => void;
	resetCart: () => void;
}

const uidSplitterString: string = "_";

export const useCart = (): useCartResponse => {
	const [currentCart, setCurrentCart] = useState<Cart>(defaultCart);

	const addItemToCart = (cartItem: CartItem): void => {
		const newCartItems: CartItem[] = cart_add(cartItem, currentCart.items);
		calculateAndSave(newCartItems);
	};

	const removeItemFromCart = (oldUid: string): void => {
		const newCartItems: CartItem[] = cart_remove(oldUid, currentCart.items);
		calculateAndSave(newCartItems);
	};

	const updateItemCart = (cartItem: CartItem): void => {
		let newCartItems = [] as CartItem[];
		if (cart_generateUid(cartItem.item, cartItem.options) === cartItem.uid) {
			newCartItems = cart_update(cartItem.uid, cartItem, currentCart.items);
		} else {
			const cleanedCartItems: CartItem[] = cart_remove(cartItem.uid, currentCart.items);
			newCartItems = cart_add(cartItem, cleanedCartItems);
		}
		calculateAndSave(newCartItems);
	};

	const calculateAndSave = (cartItems: CartItem[]): void => {
		setCurrentCart({
			amount: cart_calculatePrice(cartItems),
			itemsCount: cart_calculateQuantity(cartItems),
			items: cartItems
		} as Cart);
	};

	const resetCart = (): void => {
		setCurrentCart({ ...defaultCart });
	};

	return { cart: currentCart, addItemToCart, updateItemCart, removeItemFromCart, resetCart };
};

export const cart_generateUid = (itemSelectable: ItemSelectable, cartItemOptions: CartItem[]): string => {
	let uid: string = itemSelectable.itemInfo.uid;
	// if item could potentially contain some variations, then the uid is formed using item UID and options array hash (even if empty)
	if (itemSelectable.hasFields) {
		uid += uidSplitterString + window.btoa(JSON.stringify(cartItemOptions));
	}
	return uid;
};

export const cart_getSimplifiedUid = (uid: string): string => {
	return uid.split(uidSplitterString)[0];
};

export const cart_generateVariationUid = (uid: string, itemLinkedListId: string): string => {
	return uid + uidSplitterString + itemLinkedListId;
};

export const cart_add = (cartItem: CartItem, cartItems: CartItem[]): CartItem[] => {
	const newCartItems: CartItem[] = cloneDeep(cartItems);
	const newCartItem: CartItem = cloneDeep(cartItem);
	newCartItem.uid = cart_generateUid(newCartItem.item, newCartItem.options);

	const existingCartItem: CartItem | null = newCartItems.find((item: CartItem) => item.uid === newCartItem.uid) ?? null;

	if (existingCartItem == null) {
		newCartItems.push(newCartItem);
	} else {
		existingCartItem.quantity += newCartItem.quantity;
	}

	return newCartItems;
};

export const cart_optionAdd = (cartItem: CartItem, cartItems: CartItem[]): CartItem[] => {
	const newCartItems: CartItem[] = cloneDeep(cartItems);
	const newCartItem: CartItem = cloneDeep(cartItem);

	const existingCartItem: CartItem | null = newCartItems.find((item: CartItem) => item.uid === newCartItem.uid) ?? null;

	if (existingCartItem == null) {
		newCartItems.push(newCartItem);
	} else {
		existingCartItem.quantity += newCartItem.quantity;
	}

	return newCartItems;
};

export const cart_remove = (oldUid: string, cartItems: CartItem[]): CartItem[] => {
	const newCartItems: CartItem[] = cloneDeep(cartItems);
	const existingCartItem: CartItem | null = newCartItems.find((item: CartItem) => item.uid === oldUid) ?? null;

	if (existingCartItem !== null) {
		const index = newCartItems.indexOf(existingCartItem);
		if (index !== -1) {
			newCartItems.splice(index, 1);
		}
	}

	return newCartItems;
};

export const cart_update = (oldUid: string, cartItem: CartItem, cartItems: CartItem[]): CartItem[] => {
	const newCartItems: CartItem[] = cloneDeep(cartItems);
	const newCartItem: CartItem = cloneDeep(cartItem);
	const existingCartItem: CartItem | null = newCartItems.find((item: CartItem) => item.uid === oldUid) ?? null;

	if (existingCartItem != null) {
		existingCartItem.quantity = newCartItem.quantity;
		existingCartItem.resultingPrice = newCartItem.resultingPrice;
	}

	return newCartItems;
};

export const cart_createEmpty = (itemSelectable: ItemSelectable, quantity: number): CartItem => {
	return {
		uid: cart_generateUid(itemSelectable, []),
		item: itemSelectable,
		options: [],
		quantity: quantity ?? 1,
		resultingPrice: itemSelectable.itemInfo.price
	};
};

export const cart_calculatePrice = (cartItems: CartItem[]): number => {
	let amount: number = 0;
	cartItems.forEach((item: CartItem): void => {
		const sign: number = item.item.itemInfo.tipology === ItemInfoTypology.cond_sub ? -1 : 1;
		amount += item.quantity * item.resultingPrice * sign;
	});
	return amount;
};

export const cart_calculateQuantity = (cartItems: CartItem[]): number => {
	let quantity: number = 0;
	cartItems.forEach((item: CartItem): void => {
		quantity += item.quantity;
	});
	return quantity;
};

export const cart_clean = (cartItems: CartItem[]): CartItem[] => {
	const newCartItems: CartItem[] = cloneDeep(cartItems);

	return newCartItems.filter((item: CartItem) => item.quantity !== 0);
};
