import { getContrastRatio } from "@mui/material";
import { CustomPalette } from "../types";

/**
 * Custom palette for the application
 */
export const customPalette: CustomPalette = {
	labwareRed: "#D40F14",
	satispay: "#ef3338",
	white: "#ffffff",
	lightBlack: "rgba(0, 0, 0, 0.87)"
};

/**
 * Limit ratio for dark colors
 */
const darkLimitRatio: number = 3; // 4.5 for hightContrast

/**
 * Check if a color is dark
 * a color is considered dark when is too far from white (ratio: 3)
 * @param color string representing a color in hex format
 * @returns
 */
export function isDarkColor(color: string): boolean {
	return getContrastRatio(color, customPalette.white) > darkLimitRatio;
}

/**
 * Check if two colors have enough contrast
 * @param color1 string representing a color in hex format
 * @param color2 string representing a color in hex format
 * @returns
 */
export function areColorsContrasted(color1: string, color2: string): boolean {
	const contrastRatio = Math.round(getContrastRatio(color1, color2) * 100) / 100;
	return contrastRatio >= darkLimitRatio;
}
