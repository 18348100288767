import { DoubleArrow, HomeOutlined } from "@mui/icons-material";
import { Box, LinearProgress, Skeleton, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";
import { hasElectronicPaymentMethods, LogChannel, PaymentMethod } from "../../../constants";
import { useLogger } from "../../../hooks/useLogger";
import useOrderDocumentStack from "../../../hooks/useOrderDocumentStack";
import { orderDocument, serializeOrderDocument } from "../../../services/4Delivery";
import { ReceiptRows, ReceiptRowsFiscal, ReceiptRowsNotFiscal } from "../../../services/Rt/ReceiptRows";
import useRtPrinter from "../../../services/Rt/useRtPrinter";
import { Cart, FiscalDocument, Order, OrderDocumentResponse, PaymentErrorResponse, PrintingItem, PrintingResult, PrintRtResponseData } from "../../../types";
import i18n from "../../../utils/i18n/i18n";
import { OrderRequestManager } from "../../../utils/OrderRequestManager";
import TouchButton from "../../Layout/Buttons/TouchButton";
import { useMessage } from "../../MessageHandler/MessageService";
import { useTheme } from "../../Theme/ThemeWrapper";
import ConfirmModal from "./Wrappers/ConfirmModal";

interface ReceiptModalProps {
	cart: Cart;
	order: Order;
	closeModal: () => void;
}

const ReceiptModal = ({ cart, order, closeModal, ...rest }: ReceiptModalProps) => {
	const { theme, settings, restaurantInfo } = useTheme();
	const { t } = useTranslation();
	const message = useMessage();
	const { log, warn } = useLogger();
	const {
		RT,
		isCashSystemOutOfPaper: isPrintingQueueOutOfPaper,
		isCashSystemInError: isPrintingQueueInError,
		isPrintingQueueProcessed,
		processPrintingQueue,
		storePrintingQueue
	} = useRtPrinter();
	const { addDocumentToStack } = useOrderDocumentStack();

	const isElectronicPayment: boolean = useMemo(() => hasElectronicPaymentMethods([order.paymentMethod]), [order.paymentMethod]);

	const [isMainReceiptPrinted, setIsMainReceiptPrinted] = useState<boolean>(!settings.isCashSystemEnabled);
	const [isReceiptSentToBack, setIsReceiptSentToBack] = useState<boolean>(!settings.isFiscalMode || !isElectronicPayment);
	const [receiptInfo, setReceiptInfo] = useState<PrintRtResponseData | null>(null);
	const [isCashSystemInError, setIsCashSystemInError] = useState<boolean>(false);
	const [isHandledMainReceipt, setIsHandledMainReceipt] = useState<boolean>(false);

	const waitAndClose = useCallback(() => {
		const interval = setTimeout(() => {
			closeModal();
		}, 5000);
		return () => clearTimeout(interval);
	}, [closeModal]);

	useEffect(() => {
		const printMainReceipt = async (printingItem: PrintingItem) => {
			if (isCashSystemInError) return;

			setIsMainReceiptPrinted(true);
			await RT.printingHandler(printingItem).then((printingResult: PrintingResult) => {
				if (printingResult.isInError) {
					setIsCashSystemInError(true);
					setIsMainReceiptPrinted(false);
					message({
						title: t("system.error.cashSystem.error").toUpperCase(),
						description: t("checkout.receiptModal.payAtCashDeskWithoutReceipt") + ": " + OrderRequestManager.getServerOrderIdShortened(order),
						okCallback: () => {
							setIsCashSystemInError(false);
							setIsHandledMainReceipt(true);
							log("RT Error: shown error with order n. Fix at desk!", LogChannel.cashSystem);
						},
						okLabel: t("common.ok").toUpperCase()
					});
				} else {
					if (printingResult.isPrinted) {
						setReceiptInfo(printingResult.data);
					} else {
						message({
							title: t("system.error.cashSystem.error").toUpperCase(),
							description: t("system.error.cashSystem.statusError") + ": " + t("system.error.cashSystem.out-of-paper"),
							okCallback: () => {
								setIsMainReceiptPrinted(false);
								log("RT Error Out of paper! Adding paper the app can do ahead.", LogChannel.cashSystem);
							},
							okLabel: t("common.ok").toUpperCase()
						});
					}
				}
			});
		};

		if (!isMainReceiptPrinted) {
			const receiptMaker: ReceiptRows =
				settings.isFiscalMode && isElectronicPayment
					? new ReceiptRowsFiscal(cart, order, restaurantInfo, settings, i18n.language)
					: new ReceiptRowsNotFiscal(cart, order, restaurantInfo, settings, i18n.language);
			if (settings.receiptTallonMode) {
				storePrintingQueue(receiptMaker.generateAllTallons());
				if (settings.skipTallonRecap || !settings.isFiscalMode || !isElectronicPayment) {
					setIsHandledMainReceipt(true);
					return;
				}
			}
			printMainReceipt(receiptMaker.generateReceipt());
		}
		/* eslint-disable react-hooks/exhaustive-deps */
	}, [isMainReceiptPrinted]);

	useEffect(() => {
		const sendReceipt = async (receiptInfo: PrintRtResponseData, kioskCartId: number) => {
			const fiscalDocument: FiscalDocument = serializeOrderDocument(
				kioskCartId,
				receiptInfo,
				order.paymentMethod === PaymentMethod.STRIPE ? settings.terminalSerialNumber : undefined
			);

			await orderDocument(fiscalDocument)
				.then((response: OrderDocumentResponse) => {
					if (response.status === "order_document_created") {
						setIsReceiptSentToBack(true);
						log(`Document sent back - n: ${receiptInfo.status.progressivoZ}-${receiptInfo.status.progressivoDoc}`, LogChannel.document);
					} else if (response.status === "error") {
						const errorCode = response.last_error;
						warn(`Error from back: ${errorCode}`, LogChannel.document);
						addDocumentToStack(fiscalDocument);
					}
				})
				.catch((err: PaymentErrorResponse) => {
					warn(`Error: ${err.error_code}`, LogChannel.document);
					addDocumentToStack(fiscalDocument);
				});
		};

		if (receiptInfo != null && order.kioskCartId != null && !isCashSystemInError) {
			if (settings.isFiscalMode && isElectronicPayment && !isReceiptSentToBack) {
				sendReceipt(receiptInfo, order.kioskCartId).then(() => {
					setIsHandledMainReceipt(true);
				});
			} else {
				setIsHandledMainReceipt(true);
			}
		}
	}, [receiptInfo, isReceiptSentToBack, isCashSystemInError]);

	useEffect(() => {
		if (!isHandledMainReceipt) return;

		if (settings.receiptTallonMode) {
			if (isPrintingQueueProcessed) {
				waitAndClose();
			} else {
				processPrintingQueue();
			}
		} else {
			waitAndClose();
		}
	}, [isHandledMainReceipt, isPrintingQueueProcessed]);

	useEffect(() => {
		if (isPrintingQueueOutOfPaper) {
			message({
				title: t("system.error.cashSystem.error").toUpperCase(),
				description: t("system.error.cashSystem.statusError") + ": " + t("system.error.cashSystem.out-of-paper"),
				okCallback: () => {
					processPrintingQueue();
				},
				okLabel: t("common.retry").toUpperCase(),
				cancelCallback() {
					waitAndClose();
				},
				cancelLabel: t("common.close").toUpperCase()
			});
		}
		if (isPrintingQueueInError) {
			message({
				title: t("system.error.cashSystem.error").toUpperCase(),
				description: t("system.error.cashSystem.statusError"),
				okCallback: () => {
					waitAndClose();
				},
				okLabel: t("common.ok").toUpperCase()
			});
		}
	}, [isPrintingQueueOutOfPaper, isPrintingQueueInError, message, t]);

	return (
		<ConfirmModal>
			<Box>
				{settings.printDigitalReceipt && isElectronicPayment && (
					<Box>
						{receiptInfo !== null && receiptInfo.ultimoDocDigitale?.pdfUrl ? (
							<QRCode
								size={240}
								fgColor={theme.systemTheme.palette.text.primary}
								bgColor={theme.systemTheme.palette.background.paper}
								value={receiptInfo.ultimoDocDigitale.pdfUrl}
							/>
						) : (
							<Skeleton variant="rectangular" width={240} height={240} />
						)}
					</Box>
				)}

				{settings.isCashSystemEnabled ? (
					<>
						<Box>
							<Typography color="primary" variant="h4" textAlign="center" sx={{ marginTop: "1rem" }}>
								{t("checkout.receiptModal.thanks")}
							</Typography>
							<Typography color="default" variant="h4" textAlign="center" sx={{ marginTop: "1rem" }}>
								{settings.printDigitalReceipt && isElectronicPayment
									? t("checkout.receiptModal.instructionsScan")
									: t("checkout.receiptModal.instructions")}
							</Typography>
							<Typography color="default" variant="h4" textAlign="center" sx={{ marginTop: "1rem" }}>
								{!isElectronicPayment ? t("checkout.receiptModal.payAtCashDesk").toUpperCase() : ""}
							</Typography>
						</Box>

						<DoubleArrow sx={{ fontSize: "16rem", transform: "rotate(90deg)" }} />
					</>
				) : (
					<Box>
						<Typography color="primary" variant="h4" textAlign="center" sx={{ marginTop: "1rem" }}>
							{t("checkout.receiptModal.thanks").toUpperCase()}
						</Typography>
						<Typography color="default" variant="h4" textAlign="center" sx={{ marginTop: "1rem" }}>
							{t("checkout.receiptModal.paymentSuccess").toUpperCase()}
						</Typography>
					</Box>
				)}

				{!isHandledMainReceipt || (settings.receiptTallonMode && !isPrintingQueueProcessed) ? (
					<LinearProgress sx={{ width: "100%", marginTop: "2rem", paddingTop: ".5rem" }} color="secondary" />
				) : null}
			</Box>
			<Box
				sx={{
					display: "flex",
					justifyContent: "center"
				}}
			>
				<TouchButton
					color="success"
					variant="contained"
					size="large"
					startIcon={<HomeOutlined />}
					onClick={closeModal}
					disabled={!isHandledMainReceipt || (settings.receiptTallonMode && !isPrintingQueueProcessed)}
				>
					<Typography variant="button">{t("checkout.receiptModal.doAnOther")}</Typography>
				</TouchButton>
			</Box>
		</ConfirmModal>
	);
};

export default ReceiptModal;
