import { defaultRestaurantInfo } from "../constants/defaults";
import { RestaurantInfo } from "../types";

/**
 * Resets the localStorage while preserving specific keys and their values.
 *
 * This function clears all entries in the localStorage except for the keys specified
 * in the `keysToKeep` array. It first retrieves the values of these keys, clears the
 * entire localStorage, and then sets the preserved keys back with their original values.
 *
 * @remarks
 * This is useful in scenarios where you want to reset the application state stored in
 * localStorage but need to retain certain critical information like user credentials
 * or configuration settings.
 *
 * @example
 * ```typescript
 * resetStorage();
 * ```
 */
export const resetStorage = () => {
	const keysToKeep = ["username", "password", "kioskId", "boundaryError"];
	const valuesToKeep: { [key: string]: string | null } = {};

	// Retrieve the values of the keys to keep
	keysToKeep.forEach((key) => {
		valuesToKeep[key] = localStorage.getItem(key);
	});

	// Clear all localStorage
	localStorage.clear();

	// Set back the keys to keep
	keysToKeep.forEach((key) => {
		if (valuesToKeep[key] !== null) {
			localStorage.setItem(key, valuesToKeep[key] as string);
		}
	});
};

/**
 * Retrieves an object or array of objects from local storage by the given key.
 *
 * @param key - The key under which the object is stored in local storage.
 * @returns The parsed object or array of objects, or null if the key does not exist.
 */
export const getStorageObject = (key: string): object | object[] | null => {
	const jsonValue = localStorage.getItem(key);
	if (jsonValue == null) return null;
	const result = JSON.parse(jsonValue);
	return objNotPrototypeReviver(result);
};

/**
 * Stores an object in the local storage under the specified key.
 *
 * @param key - The key under which the object will be stored.
 * @param value - The object to be stored. It will be serialized to a JSON string.
 */
export const setStorageObject = (key: string, value: any) => {
	const jsonValue = JSON.stringify(value);
	localStorage.setItem(key, jsonValue);
};

/**
 * Revives an object or an array of objects by creating a new object with no prototype.
 * This is useful for preventing prototype pollution.
 *
 * @param value - The object or array of objects to be revived.
 * @returns The revived object or array of objects with no prototype, or the original value if it is not an object.
 */
const objNotPrototypeReviver = (value: object | object[]) => {
	if (value && typeof value === "object") {
		if (Array.isArray(value)) {
			value.map((v) => (v && typeof v === "object" && !Array.isArray(v) ? Object.assign(Object.create(null), v) : v));
		} else {
			return Object.assign(Object.create(null), value);
		}
	}
	return value;
};

/**
 * Get kioskauth header value from local storage.
 * @returns The kioskauth header value.
 */
export const getKioskAuth = (): string => {
	return (localStorage.getItem("username") ?? "") + ";" + (localStorage.getItem("password") ?? "") + ";" + (localStorage.getItem("kioskId") ?? "");
};

/**
 * Get the restaurant info from local storage.
 * @returns The restaurant info object.
 */
export const getRestaurantInfo = (): RestaurantInfo => {
	const info: RestaurantInfo = (getStorageObject("restaurant_info") as RestaurantInfo) ?? defaultRestaurantInfo;

	return {
		country: info.country ?? defaultRestaurantInfo.country,
		currency: info.currency === "" ? defaultRestaurantInfo.currency : info.currency,
		config: info.config ?? defaultRestaurantInfo.config,
		hide_snoozed: info.hide_snoozed ?? defaultRestaurantInfo.hide_snoozed,
		media: info.media ?? defaultRestaurantInfo.media,
		media_baseurl: info.media_baseurl ?? defaultRestaurantInfo.media_baseurl,
		default_stripe_location: info.default_stripe_location ?? defaultRestaurantInfo.default_stripe_location,
		device_token: info.device_token ?? defaultRestaurantInfo.device_token,
		restaurant_name: info.restaurant_name ?? defaultRestaurantInfo.restaurant_name
	};
};
