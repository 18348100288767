import { Close, ContactSupport, LogoutRounded, Refresh, SystemUpdate, Update } from "@mui/icons-material";
import { alpha, Box, Button, darken, lighten, Paper, Stack, Typography } from "@mui/material";
import { memo, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { LogChannel, RoutePath, WebviewApp } from "../../constants";
import { defaultRestaurantInfo } from "../../constants/defaults";
import { useLogger } from "../../hooks/useLogger";
import { useWebview } from "../../hooks/useWebview";
import { AvailableRelease } from "../../types/reactNative/bridgeOutput";
import { isDarkColor } from "../../utils/color";
import { compareVersions, getMajorVersion } from "../../utils/version";
import AdminDivider from "../Layout/Divider/AdminDivider";
import InputSave from "../Layout/Form/InputSave";
import { useMessage } from "../MessageHandler/MessageService";
import { useTheme } from "../Theme/ThemeWrapper";

interface AdminAccessProps {
	isAdmin: boolean;
	currentPin: string;
	currentPinOperator: string;
	currentAppVersion: string;
	iqSelfImage: any;
	iqSelfFaviconImage: any;
	logoAnyDeskImage: any;
	setCurrentPin: (currentPin: string) => void;
	setCurrentPinOperator: (currentPinOperator: string) => void;
}

const AdminAccess = ({
	isAdmin,
	currentPin,
	currentPinOperator,
	currentAppVersion,
	iqSelfImage,
	iqSelfFaviconImage,
	logoAnyDeskImage,
	setCurrentPin,
	setCurrentPinOperator
}: AdminAccessProps) => {
	const { theme, restaurantId, restaurantInfo, bridge, container, setContextStripeLocationId, setContextRestaurantId, setContextRestaurantPwd } = useTheme();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const message = useMessage();
	const { log } = useLogger();
	const { appClose, appReload } = useWebview();
	const currentAppVersionMajor = getMajorVersion(currentAppVersion);

	const [updatesSwFound, setUpdatesSwFound] = useState<boolean>();
	const [updatesAppFound, setUpdatesAppFound] = useState<AvailableRelease | null>();

	const palette = theme.systemTheme.palette;

	const greyColor: string = useMemo(
		() => alpha(isDarkColor(palette.background.paper) ? lighten(palette.background.paper, 0.05) : darken(palette.background.paper, 0.05), 0.8),
		[palette]
	);
	const greyColorMoreContrast: string = useMemo(
		() => (isDarkColor(palette.background.paper) ? lighten(palette.background.paper, 0.1) : darken(palette.background.paper, 0.1)),
		[palette]
	);

	const gradientStyle = useMemo(
		() => ({
			background: `linear-gradient(
				0deg, 
				${greyColorMoreContrast} 0%, 
				${greyColor} 2%, 
				${palette.background.paper} 50%,
				${palette.background.paper} 100%
			)`
		}),
		[greyColor, greyColorMoreContrast, palette.background.paper]
	);

	const handleLogout = () => {
		message({
			title: t("common.logout"),
			description: t("system.admin.access.logoutConfirm"),
			okCallback: () => {
				log(`Log out`, LogChannel.authentication);
				if (setContextStripeLocationId) setContextStripeLocationId(null);
				if (setContextRestaurantId) setContextRestaurantId("");
				if (setContextRestaurantPwd) setContextRestaurantPwd("");
				localStorage.clear();
				navigate(RoutePath.homePage);
			},
			okLabel: t("common.yes"),
			cancelCallback: () => {},
			cancelLabel: t("common.cancel") ?? ""
		});
	};

	const handleSwUpdates = () => {
		try {
			navigator.serviceWorker.ready
				.then((registration) => {
					registration
						.update()
						.then(() => {
							setUpdatesSwFound(registration.installing !== null || registration.waiting !== null);
						})
						.catch((error) => {
							setUpdatesSwFound(false);
						});
				})
				.catch((error) => {
					setUpdatesSwFound(false);
				});
		} catch (error) {
			setUpdatesSwFound(false);
		}
	};

	const handleCheckAppUpdates = () => {
		if (container === WebviewApp.reactNative && bridge.bridgeActive && restaurantInfo.device_token && currentAppVersionMajor) {
			bridge
				.checkUpdate(restaurantInfo.device_token ?? "")
				.then((releases: AvailableRelease[]) => {
					const thisMajorRelease = releases.find((release: AvailableRelease) => Number(release.major) === currentAppVersionMajor);
					if (!thisMajorRelease) throw new Error("No release found");

					const hasNewerVersion: boolean = compareVersions(thisMajorRelease.release, currentAppVersion) > 0;
					if (hasNewerVersion) {
						setUpdatesAppFound(thisMajorRelease);
					} else {
						throw new Error("No newer version found");
					}
				})
				.catch((error) => {
					setUpdatesAppFound(null);
				});
		}
	};

	const handleDownloadAppUpdate = () => {
		if (container === WebviewApp.reactNative && bridge.bridgeActive && bridge.installUpdate && restaurantInfo.device_token && updatesAppFound) {
			message({
				title: t("system.admin.access.updates"),
				description: t("system.admin.access.downloadAppConfirm"),
				okCallback: () => {
					bridge
						.installUpdate(restaurantInfo.device_token ?? "", updatesAppFound)
						.then((success: boolean) => {
							if (!success) {
								throw new Error("Update failed");
							}
						})
						.catch((error) => {})
						.finally(() => setUpdatesAppFound(undefined));
				},
				okLabel: t("common.yes"),
				cancelCallback: () => {},
				cancelLabel: t("common.cancel") ?? ""
			});
		}
	};

	const handleCloseApp = () => {
		message({
			title: t("system.admin.access.closeApp"),
			description: t("system.admin.access.closeAppConfirm"),
			okCallback: () => {
				appClose();
			},
			okLabel: t("common.yes"),
			cancelCallback: () => {},
			cancelLabel: t("common.cancel") ?? ""
		});
	};
	const handleReloadApp = () => {
		appReload();
	};

	const handleOpenAnyDesk = () => {
		if (container === WebviewApp.reactNative && bridge.bridgeActive && bridge.openAnyDesk) {
			bridge.openAnyDesk().catch((error) => {});
		}
	};

	const handleSavePin = (newPin: string | number): void => {
		// PIN administrator and PIN operator must be different
		if (newPin.toString() === currentPinOperator) {
			message({
				title: t("common.crud.edit", { item: t("system.admin.access.pin") }),
				description: t("system.admin.access.alertPinDifferent"),
				okCallback: () => {},
				okLabel: t("common.ok")
			});
		} else {
			setCurrentPin(newPin.toString());
		}
	};

	const handleSavePinOperator = (newPinOperator: string | number): void => {
		// PIN administrator and PIN operator must be different
		if (newPinOperator.toString() === currentPin) {
			message({
				title: t("common.crud.edit", { item: t("system.admin.access.pin") }),
				description: t("system.admin.access.alertPinDifferent"),
				okCallback: () => {},
				okLabel: t("common.ok")
			});
		} else {
			setCurrentPinOperator(newPinOperator.toString());
		}
	};

	return (
		<Paper sx={{ p: "1.5rem 0" }} elevation={0}>
			<AdminDivider label={t("system.admin.access.auth")} />
			<Box>
				<br />
				<Stack className="appRow" direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={gradientStyle}>
					<Stack direction={"row"} alignItems={"center"}>
						<Stack direction={"column"} justifyContent={"center"}>
							<Typography variant="h6">{restaurantInfo.restaurant_name ?? ""}</Typography>
							<Typography variant="subtitle2">{t("system.admin.access.restaurantId") + " " + restaurantId}</Typography>
							<Typography variant="subtitle2">{t("common.country") + " " + restaurantInfo.country}</Typography>
							<Typography variant="subtitle2">{t("common.currency") + " " + restaurantInfo.currency}</Typography>
						</Stack>
					</Stack>
					<Stack direction={"row"} alignItems={"center"}>
						<Button
							className="appAction"
							variant="outlined"
							color="secondary"
							onClick={handleLogout}
							endIcon={<LogoutRounded />}
							disabled={!isAdmin}
						>
							{t("common.logout")}
						</Button>
					</Stack>
				</Stack>
			</Box>
			{isAdmin ? (
				<>
					<AdminDivider label={t("system.admin.access.pin")} />
					<Box>
						<br />
						<Stack direction={"row"} justifyContent={"start"} alignItems={"center"}>
							<InputSave
								color="secondary"
								label={t("system.admin.access.pinAdministrator")}
								value={currentPin}
								onSave={handleSavePin}
								type="number"
								InputProps={{ inputProps: { min: 0, max: 9999, maxLength: 4, minLength: 4 } }}
								sx={{ mr: "1.5rem" }}
							/>
							<InputSave
								color="secondary"
								label={t("system.admin.access.pinOperator")}
								value={currentPinOperator}
								onSave={handleSavePinOperator}
								type="number"
								InputProps={{ inputProps: { min: 0, max: 9999, maxLength: 4, minLength: 4 } }}
							/>
						</Stack>
					</Box>
				</>
			) : null}

			{container === WebviewApp.reactNative && bridge.bridgeActive && typeof bridge.openAnyDesk === "function" ? (
				<>
					<AdminDivider label={t("system.admin.access.support")} />
					<Box>
						<br />
						<Stack className="appRow" direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={gradientStyle}>
							<Stack direction={"row"} alignItems={"center"}>
								<Box>{iqSelfImage ? <img className="iqSelfLogo" src={logoAnyDeskImage} alt={"AnyDesk"} loading="lazy" /> : null}</Box>
								<Stack direction={"column"} justifyContent={"center"}>
									<Typography variant="h6">{"AnyDesk"}</Typography>
								</Stack>
							</Stack>
							<Stack direction={"row"} alignItems={"center"}>
								<Button
									className="appAction"
									variant="outlined"
									color="secondary"
									onClick={handleOpenAnyDesk}
									endIcon={<ContactSupport />}
									disabled={!isAdmin}
								>
									{t("common.open")}
								</Button>
							</Stack>
						</Stack>
					</Box>
				</>
			) : null}

			<AdminDivider label={t("system.admin.access.software")} />
			<Box>
				<br />
				<Stack className="appRow" direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={gradientStyle}>
					<Stack direction={"row"} alignItems={"center"}>
						<Box>{iqSelfImage ? <img className="iqSelfLogo" src={iqSelfImage} alt={t("system.admin.title")} loading="lazy" /> : null}</Box>
						<Stack direction={"column"} justifyContent={"center"}>
							<Typography variant="h6">{t("system.admin.title")}</Typography>
							<Typography variant="subtitle2">{defaultRestaurantInfo.config.versionNumber}</Typography>
						</Stack>
					</Stack>
					<Stack direction={"row"} alignItems={"center"}>
						{updatesSwFound === undefined ? (
							<Button
								className="appAction"
								variant="outlined"
								color="secondary"
								onClick={handleSwUpdates}
								endIcon={<Update />}
								disabled={!isAdmin}
							>
								{t("system.admin.access.checkForUpdates")}
							</Button>
						) : (
							<Typography variant="subtitle2">
								{updatesSwFound ? t("system.admin.access.updatesFound") : t("system.admin.access.noUpdates")}
							</Typography>
						)}

						<Button className="appAction" variant="outlined" color="secondary" onClick={handleReloadApp} endIcon={<Refresh />} sx={{ ml: 2 }}>
							{t("common.reload")}
						</Button>
					</Stack>
				</Stack>
			</Box>

			<AdminDivider label={t("system.admin.access.app")} />
			{container === WebviewApp.reactNative && bridge.bridgeActive && currentAppVersionMajor ? (
				<>
					<Box>
						<br />
						<Stack className="appRow" direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={gradientStyle}>
							<Stack direction={"row"} alignItems={"center"}>
								<Box className="iqSelfFavicon">
									{iqSelfFaviconImage ? (
										<img className="iqSelfFaviconLogo" src={iqSelfFaviconImage} alt={t("system.admin.access.appTitle")} loading="lazy" />
									) : null}
								</Box>
								<Stack direction={"column"} justifyContent={"center"}>
									<Typography variant="h6">{t("system.admin.access.appTitle")}</Typography>
									<Typography variant="subtitle2">{currentAppVersion}</Typography>
								</Stack>
							</Stack>
							<Stack direction={"row"} alignItems={"center"}>
								{updatesAppFound === undefined ? (
									<Button
										className="appAction"
										variant="outlined"
										color="secondary"
										onClick={handleCheckAppUpdates}
										endIcon={<Update />}
										disabled={!isAdmin}
									>
										{t("system.admin.access.checkForUpdates")}
									</Button>
								) : updatesAppFound === null ? (
									<Typography variant="subtitle2">{t("system.admin.access.noUpdates")}</Typography>
								) : (
									<Button
										className="appAction"
										variant="outlined"
										color="secondary"
										onClick={handleDownloadAppUpdate}
										endIcon={<SystemUpdate />}
										disabled={!isAdmin}
									>
										{t("common.update")}
									</Button>
								)}
							</Stack>
						</Stack>
					</Box>
				</>
			) : null}

			<Box>
				<br />
				<Button variant="contained" color="primary" onClick={handleCloseApp} endIcon={<Close />} sx={{ mr: "1.5rem", width: "15rem" }}>
					{t("system.admin.access.closeApp")}
				</Button>
			</Box>
		</Paper>
	);
};

export default memo(AdminAccess);
