import { DoubleArrow, HomeOutlined } from "@mui/icons-material";
import { Box, LinearProgress, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { hasElectronicPaymentMethods, LogChannel } from "../../../constants";
import { useLogger } from "../../../hooks/useLogger";
import { TicketGenerator } from "../../../services/Coatcheck/TicketGenerator";
import useCcPrinter from "../../../services/Coatcheck/useCcPrinter";
import { Cart, MenuTicketFormat, Order, PrintingItemCc } from "../../../types";
import i18n from "../../../utils/i18n/i18n";
import { OrderRequestManager } from "../../../utils/OrderRequestManager";
import TouchButton from "../../Layout/Buttons/TouchButton";
import { useMessage } from "../../MessageHandler/MessageService";
import { useTheme } from "../../Theme/ThemeWrapper";
import ConfirmModal from "./Wrappers/ConfirmModal";

interface ReceiptCCModalProps {
	cart: Cart;
	order: Order;
	menuTickets: MenuTicketFormat[];
	closeModal: () => void;
}

const ReceiptCCModal = ({ cart, order, menuTickets, closeModal }: ReceiptCCModalProps) => {
	const { settings } = useTheme();
	const { t } = useTranslation();
	const message = useMessage();
	const { log } = useLogger();
	const {
		isPrintingQueueProcessed,
		isCashSystemOutOfPaper,
		isCashSystemInError,
		queueInitialLength,
		queueCurrentLength,
		processPrintingQueue,
		storePrintingQueue
	} = useCcPrinter();

	const shortOrder: string = useMemo(() => OrderRequestManager.getServerOrderIdShortened(order), [order]);
	const isElectronicPayment: boolean = useMemo(() => hasElectronicPaymentMethods([order.paymentMethod]), [order.paymentMethod]);

	const ticketsToPrint: PrintingItemCc[] = useMemo(() => {
		const ticketGenerator = new TicketGenerator(cart, settings, menuTickets, order.paymentMethod, i18n.language);
		return ticketGenerator.make();
	}, [cart, settings, menuTickets, order.paymentMethod]);

	useEffect(() => {
		if (queueInitialLength === 0 || queueCurrentLength === 0) return;
		log(`CC queue ${queueInitialLength - queueCurrentLength + 1}/${queueInitialLength}`, LogChannel.cashSystem);
	}, [queueInitialLength, queueCurrentLength, log]);

	useEffect(() => {
		if (isCashSystemOutOfPaper) {
			message({
				title: t("system.error.cashSystem.error").toUpperCase(),
				description: t("system.error.cashSystem.statusError") + ": " + t("system.error.cashSystem.out-of-paper"),
				okCallback: () => {
					processPrintingQueue();
				},
				okLabel: t("common.retry").toUpperCase(),
				cancelCallback: () => closeModal(),
				cancelLabel: t("common.back").toUpperCase()
			});
		}
		if (isCashSystemInError) {
			message({
				title: t("system.error.cashSystem.error").toUpperCase(),
				description: t("system.error.cashSystem.statusError") + " " + t("bill.order") + ": " + shortOrder,
				okCallback: () => closeModal(),
				okLabel: t("common.ok").toUpperCase()
			});
		}
		/* eslint-disable react-hooks/exhaustive-deps */
	}, [isCashSystemOutOfPaper, isCashSystemInError]);

	const waitAndClose = useCallback(() => {
		const interval = setTimeout(() => {
			closeModal();
		}, 5000);
		return () => clearTimeout(interval);
	}, [closeModal]);

	useEffect(() => {
		if (!isPrintingQueueProcessed) return;

		waitAndClose();
	}, [isPrintingQueueProcessed, waitAndClose]);

	useEffect(() => {
		storePrintingQueue({ queue: ticketsToPrint });
		processPrintingQueue();
		/* eslint-disable react-hooks/exhaustive-deps */
	}, []);

	return (
		<ConfirmModal>
			<Box>
				{settings.isCashSystemEnabled ? (
					<>
						<Box>
							<Typography color="primary" variant="h4" textAlign="center" sx={{ marginTop: "1rem" }}>
								{t("checkout.receiptModal.thanks")}
							</Typography>
							<Typography color="default" variant="h4" textAlign="center" sx={{ marginTop: "1rem" }}>
								{settings.printDigitalReceipt && isElectronicPayment
									? t("checkout.receiptModal.instructionsScan")
									: t("checkout.receiptModal.instructions")}
							</Typography>
							<Typography color="default" variant="h4" textAlign="center" sx={{ marginTop: "1rem" }}>
								{!isElectronicPayment ? t("checkout.receiptModal.payAtCashDesk").toUpperCase() : ""}
							</Typography>
						</Box>

						<DoubleArrow sx={{ fontSize: "16rem", transform: "rotate(90deg)" }} />
					</>
				) : (
					<Box>
						<Typography color="primary" variant="h4" textAlign="center" sx={{ marginTop: "1rem" }}>
							{t("checkout.receiptModal.thanks").toUpperCase()}
						</Typography>
						<Typography color="default" variant="h4" textAlign="center" sx={{ marginTop: "1rem" }}>
							{t("checkout.receiptModal.paymentSuccess").toUpperCase()}
						</Typography>
					</Box>
				)}

				{!isPrintingQueueProcessed ? (
					<>
						{queueInitialLength !== 0 && queueCurrentLength !== 0 ? (
							<Typography variant="body1" textAlign="center" sx={{ marginTop: "1rem" }}>
								{queueInitialLength - queueCurrentLength + 1 + "/" + queueInitialLength}
							</Typography>
						) : null}
						<LinearProgress sx={{ width: "100%", marginTop: "2rem", paddingTop: ".5rem" }} color="secondary" />
					</>
				) : null}
			</Box>
			<Box
				sx={{
					display: "flex",
					justifyContent: "center"
				}}
			>
				<TouchButton
					color="success"
					variant="contained"
					size="large"
					startIcon={<HomeOutlined />}
					onClick={closeModal}
					disabled={!isPrintingQueueProcessed}
				>
					<Typography variant="button">{t("checkout.receiptModal.doAnOther")}</Typography>
				</TouchButton>
			</Box>
		</ConfirmModal>
	);
};

export default ReceiptCCModal;
