import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LogChannel, PaymentRequestStatus, WebviewApp } from "../../../../constants";
import { useLogger } from "../../../../hooks/useLogger";
import { useMessage } from "../../../MessageHandler/MessageService";
import { useTheme } from "../../../Theme/ThemeWrapper";

interface CheckReaderRNProps {
	setRequestStatus: Dispatch<SetStateAction<PaymentRequestStatus>>;
	setConnecting: Dispatch<SetStateAction<boolean>>;
	cancelOrder: () => void;
}
const CheckReaderRN = ({ setRequestStatus, setConnecting, cancelOrder }: CheckReaderRNProps) => {
	const { container, bridge } = useTheme();
	const { t } = useTranslation();
	const message = useMessage();
	const { log, warn } = useLogger();

	const [isUsbReaderConnected, setUsbReaderConnected] = useState<boolean>(false);
	const [isUsbReaderChecked, setUsbReaderChecked] = useState<boolean>(false);

	/**
	 * Handle the error message when the USB reader is not
	 * reachable and the user wants to cancel the order
	 */
	const handleError = useCallback(() => {
		warn("USB reader not reachable", LogChannel.payment);
		message({
			title: "",
			description: t("system.error.reader_not_found") + ". " + t("system.error.staff"),
			okLabel: t("common.ok"),
			okCallback: () => {
				cancelOrder();
			}
		});
	}, [warn, message, t, cancelOrder]);

	/**
	 * Check USB reader connection and update states
	 */
	const getUsbReaderConnection = useCallback(async (): Promise<void> => {
		if (container === WebviewApp.reactNative && bridge.bridgeActive) {
			const connected = await bridge.testUsbStripeReader();
			setUsbReaderConnected(connected);
		}
		setUsbReaderChecked(true);
	}, [bridge, container]);

	// if the available methods is empty... try to get the USB reader connection again every 4 seconds
	// limit the attempts to 28 seconds (7 attempts) and update the state
	useEffect(() => {
		if (container === WebviewApp.reactNative && bridge.bridgeActive && isUsbReaderChecked && !isUsbReaderConnected) {
			let attempts = 0;
			const interval = setInterval(() => {
				if (attempts < 7) {
					log(`check the USB reader connection... ${attempts}`, LogChannel.payment);
					getUsbReaderConnection();
					attempts++;
				} else {
					clearInterval(interval);
					setConnecting(false);
					handleError();
				}
			}, 4000);
			return () => clearInterval(interval);
		}
	}, [bridge, container, getUsbReaderConnection, isUsbReaderChecked, isUsbReaderConnected, log, handleError, setConnecting]);

	useEffect(() => {
		setConnecting(true);
		getUsbReaderConnection();
	}, [getUsbReaderConnection, setConnecting]);

	useEffect(() => {
		if (isUsbReaderConnected) {
			setConnecting(false);
			setRequestStatus(PaymentRequestStatus.creatingIntent);
		}
	}, [isUsbReaderConnected, log, setRequestStatus, setConnecting]);

	return null;
};

export default CheckReaderRN;
