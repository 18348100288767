/**
 * Convert a numeric value (or a string representing the value) to cents
 * @param value  The value to convert. It's expected to be a two digit floating point number or a string representing a two digit floating point number
 * @returns
 */
export function valueToCents(value: string | number): number {
	// If the value is a string, convert it to a number
	if (typeof value === "string") {
		value = Number(value);
	}
	// If the value is not a number or is NaN, return 0
	if (typeof value !== "number" || isNaN(value)) {
		return 0;
	}
	// round the value to two decimal places and multiply by 100
	return Math.round(value * 100);
}

/**
 * Convert an integer number expressing cents (or a string representing this number) to its equivalent in the base unit
 * @param cents The number of cents to convert. It's expected to be an integer or a string representing an integer
 * @returns
 */
export function centsToValue(cents: string | number): number {
	// If the value is a string, convert it to a number
	if (typeof cents === "string") {
		cents = Number(cents);
	}
	// If the value is not a number or is NaN, return 0
	if (typeof cents !== "number" || isNaN(cents)) {
		return 0;
	}
	// Divide the value by 100
	return Number(Math.round(cents)) / 100;
}
